import React, { useState } from 'react';
import { useMutation } from '@apollo/react-hooks';

import { REMOVE_SCOPING_NEXT_STEP } from '../../../../graphql/Scoping';

import NextStepsPopup from './components/NextStepsPopup/NextStepsPopup';
import Draft from '../Blocks/Question/Draft/Draft';
import Popup from '../../Popup';
import ConfirmationDialog from '../../ConfirmationDialog/ConfirmationDialog';

import resultsPageGoalIcon from '../../../../assets/img/results-page-goal-icon.svg';
import resultsPageValidateIcon from '../../../../assets/img/results-page-validate-icon.svg';
import resultsPageTargetIcon from '../../../../assets/img/results-page-target-audience-icon.svg';
import resultsPageStepsIcon from '../../../../assets/img/results-page-steps-icon.svg';
import arrowGrey from '../../../../assets/img/arrow-down.svg';

import styles from './Scoping.module.css';

const Scoping = props => {
  const {
    surveyId,
    scoping,
    recordDetails,
    nextStepPopup,
    setNextStepPopup,
    surveyRefetch,
    surveyHasInsights
  } = props;
  const [removeScopingNextStep] = useMutation(REMOVE_SCOPING_NEXT_STEP);

  const [confirmationPopup, setConfirmationPopup] = useState(null);
  const [internalData, setInternalData] = useState({});
  const [isNextStepsExpanded, setIsNextStepsExpanded] = useState(true);

  const recordBusinessSettings =
    recordDetails && recordDetails.businessSettings
      ? JSON.parse(recordDetails.businessSettings)
      : null;

  const onEditClick = nextStep => {
    setInternalData(nextStep);
    setNextStepPopup({
      nextStepId: nextStep._id
    });
  };

  const onDeleteClick = async nextStepId => {
    await removeScopingNextStep({
      variables: {
        id: nextStepId,
        survey: surveyId.toString()
      }
    });

    surveyRefetch();
  };

  const getRichTextString = blockQuestion => {
    let question;
    try {
      question = JSON.parse(blockQuestion)
        .blocks.map(draftBlock => draftBlock.text)
        .join(' ');
    } catch (error) {
      question = blockQuestion;
    }
    return question;
  };

  return (
    <>
      {nextStepPopup ? (
        <NextStepsPopup
          internalData={internalData}
          setInternalData={setInternalData}
          surveyId={surveyId}
          nextStepPopup={nextStepPopup}
          setNextStepPopup={setNextStepPopup}
          getRichTextString={getRichTextString}
          surveyRefetch={surveyRefetch}
        />
      ) : null}

      {!recordBusinessSettings &&
      (scoping.actionsOrDecisions || scoping.unknownActionsOrDecisions) ? (
        <div className={styles.container} style={{ paddingTop: 0 }}>
          <div className={`${styles.title} ${styles.titleBackgroundDark}`}>
            <img
              src={resultsPageGoalIcon}
              className={styles.titleIcon}
              alt="Validate icon"
            />
            What actions or decisions will be taken in the organisation based on
            the outcome of the research?
          </div>
          <div className={styles.textContainer}>
            <div className={styles.textField}>
              {scoping.unknownActionsOrDecisions
                ? 'I don’t know'
                : scoping.actionsOrDecisions}
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.container}>
            <div className={`${styles.title} ${styles.titleBackgroundDark}`}>
              <img
                src={resultsPageGoalIcon}
                className={styles.titleIcon}
                alt="Validate icon"
              />
              Company goal(s)
            </div>
            <div className={styles.textContainer}>
              <div className={styles.textField}>
                {recordBusinessSettings &&
                recordBusinessSettings.GOAL &&
                recordBusinessSettings.GOAL.recordNames &&
                recordBusinessSettings.GOAL.recordNames.length
                  ? recordBusinessSettings.GOAL.recordNames.map(recordName => (
                      <div key={recordName}>{recordName}</div>
                    ))
                  : '/'}
              </div>
            </div>
          </div>

          <div className={styles.container}>
            <div className={`${styles.title} ${styles.titleBackgroundDark}`}>
              <img
                src={resultsPageGoalIcon}
                className={styles.titleIcon}
                alt="Validate icon"
              />
              Business challenge
            </div>
            <div className={styles.textContainer}>
              <div className={styles.textField}>
                {recordBusinessSettings &&
                recordBusinessSettings.CHALLENGE &&
                recordBusinessSettings.CHALLENGE.recordNames &&
                recordBusinessSettings.CHALLENGE.recordNames.length
                  ? recordBusinessSettings.CHALLENGE.recordNames.map(
                      recordName => (
                        <div className={styles.textFieldTitle} key={recordName}>
                          {recordName}
                        </div>
                      )
                    )
                  : '/'}
                <div>
                  {(recordBusinessSettings &&
                    recordBusinessSettings.CHALLENGE &&
                    recordBusinessSettings.CHALLENGE.description) ||
                    ''}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      <div className={styles.container}>
        <div className={`${styles.title} ${styles.titleBackgroundLight}`}>
          <img
            src={resultsPageValidateIcon}
            className={styles.titleIcon}
            alt="Validate icon"
          />
          What did you want to learn or validate?
        </div>
        <div className={styles.textContainer}>
          <div className={styles.textField}>
            <div>{scoping.assumptionToValidate}</div>
          </div>
        </div>
      </div>

      <div className={styles.container}>
        <div className={`${styles.title} ${styles.titleBackgroundLight}`}>
          <img
            src={resultsPageTargetIcon}
            className={styles.titleIcon}
            alt="Target audience icon"
          />
          Target audience
        </div>
        <div className={styles.textContainer}>
          <div className={styles.textField}>
            <div>{scoping.targetAudienceDescription}</div>
          </div>
        </div>
      </div>

      {surveyHasInsights ? (
        <div className={styles.container}>
          <div
            className={`${styles.title} ${styles.titleBackgroundDark} ${
              styles.nextStepsTitle
            } ${!isNextStepsExpanded ? `${styles.collapsedTitle}` : ``}`}
            role="presentation"
            onClick={() => setIsNextStepsExpanded(!isNextStepsExpanded)}
          >
            <img
              src={resultsPageStepsIcon}
              className={styles.titleIcon}
              alt="Steps icon"
            />
            Next steps based on insights
            <img
              src={arrowGrey}
              className={
                isNextStepsExpanded
                  ? styles.arrowIcon
                  : `${styles.arrowIcon} ${styles.rotatedArrow}`
              }
              alt="Expand-colapse icon"
            />
          </div>
          {isNextStepsExpanded ? (
            <div className={styles.nextStepsContainer}>
              {scoping && scoping.nextSteps && scoping.nextSteps.length ? (
                <div>
                  {scoping.nextSteps.map(nextStep => (
                    <div className={styles.textContainer} key={nextStep._id}>
                      <Draft
                        block={{
                          question: nextStep.description,
                          type: 'Next step'
                        }}
                      />
                      <div className={styles.showActionDropdownButton}>
                        ...
                        <div className={styles.actionDropdownContainer}>
                          <div
                            className={styles.actionDropdownContainerOption}
                            role="presentation"
                            onClick={e => {
                              e.stopPropagation();
                              onEditClick(nextStep);
                            }}
                          >
                            Edit
                          </div>
                          <div
                            className={styles.actionDropdownContainerOption}
                            role="presentation"
                            onClick={e => {
                              e.stopPropagation();
                              setConfirmationPopup({
                                actionLabel:
                                  'Are you sure you want to delete this next step?',
                                confirmLabel: 'Delete',
                                cancelLabel: 'Cancel',
                                onConfirm: async () => {
                                  await onDeleteClick(nextStep._id);
                                  setConfirmationPopup(null);
                                }
                              });
                            }}
                          >
                            Delete
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : null}
              <div
                className={`${styles.textContainer} ${styles.addNextStep}`}
                role="presentation"
                onClick={() => {
                  setNextStepPopup(true);
                }}
              >
                <span className={styles.plusIcon}>+</span>Add next step based on
                insight(s)
              </div>
            </div>
          ) : null}
        </div>
      ) : null}

      {confirmationPopup ? (
        <Popup
          component={
            <ConfirmationDialog
              label={confirmationPopup.actionLabel}
              confirmLabel={confirmationPopup.confirmLabel}
              cancelLabel={confirmationPopup.cancelLabel}
              onConfirm={() => {
                confirmationPopup.onConfirm();
              }}
              onCancel={() => {
                setConfirmationPopup(null);
              }}
            />
          }
          onClose={() => {
            setConfirmationPopup(null);
          }}
        />
      ) : null}
    </>
  );
};

export default Scoping;
