import React, { useState, useRef } from 'react';
import useLazyQuery from '../../../../../../../users/hooks/useLazyQuery'; // Use from users because other params (to be fixed)

import Dropdown from '../../../Dropdown/Dropdown';

import { businessConfigurationTypes } from '../../../../helpers/constants';

import {
  GET_BUSINESS_CONFIG_RECORDS,
  GET_BUSINESS_CONFIG_RECORDS_WITH_PARENTS
} from '../../../../../../graphql/AccountManagement';

import questionMark from '../../../../../../assets/img/accountmanagement/am-questionmark.svg';

import styles from './BusinessSettings.module.css';

export default ({
  internalRecord,
  setInternalRecord,
  businessSettingsTitles
}) => {
  const [fixedTooltipPosition, setFixedTooltipPosition] = useState({
    top: -1000,
    left: -1000
  }); // hacky but works
  const [tooltipHovered, setTooltipHovered] = useState(false);

  const tooltipRef = useRef(null);

  const updateTooltipPosition = () => {
    if (tooltipRef && tooltipRef.current && !tooltipHovered) {
      const rect = tooltipRef.current.getBoundingClientRect();
      const win = tooltipRef.current.ownerDocument.defaultView;

      const top = rect.top + win.pageYOffset - window.scrollY - 10;
      const left = rect.left + win.pageXOffset - window.scrollX + 25;

      setFixedTooltipPosition({ top, left });
    }

    setTooltipHovered(prev => !prev);
  };

  const getSingleFieldValue = (type, field) => {
    if (
      internalRecord &&
      internalRecord.businessSettings &&
      internalRecord.businessSettings.length
    ) {
      const typeArray = internalRecord.businessSettings.find(
        tA => tA.type === type
      );
      if (typeArray && typeArray[field]) {
        return typeArray[field];
      }
    }
    return '';
  };

  const getArrayFieldValue = (type, field) => {
    if (
      internalRecord &&
      internalRecord.businessSettings &&
      internalRecord.businessSettings.length
    ) {
      const typeArray = internalRecord.businessSettings.find(
        tA => tA.type === type
      );
      if (typeArray && typeArray[field]) {
        return typeArray[field];
      }
    }
    return [];
  };

  const isOtherOptionSelected = type =>
    internalRecord &&
    internalRecord.businessSettings &&
    internalRecord.businessSettings.length &&
    internalRecord.businessSettings.some(
      settings =>
        settings.type === type &&
        settings.records &&
        settings.records.length &&
        settings.records.some(r => r.id === 'Other')
    );

  const updateInternalRecord = (type, field, value) => {
    let newBusinessTypeObject = {};

    const businessTypeObject =
      internalRecord &&
      internalRecord.businessSettings &&
      internalRecord.businessSettings.length
        ? internalRecord.businessSettings.find(tA => tA.type === type)
        : null;

    if (businessTypeObject) {
      newBusinessTypeObject = {
        ...businessTypeObject,
        [field]: value
      };
    } else {
      newBusinessTypeObject = {
        type,
        [field]: value
      };
    }
    const newBusinessSettings = businessTypeObject
      ? internalRecord.businessSettings.reduce((acc, curr) => {
          if (curr.type === type) {
            return [...acc, newBusinessTypeObject];
          }
          return [...acc, curr];
        }, [])
      : [
          ...((internalRecord && internalRecord.businessSettings) || []),
          newBusinessTypeObject
        ];

    setInternalRecord({
      ...internalRecord,
      businessSettings: newBusinessSettings
    });
  };

  const onSelectGoalCompleted = (record, type) => {
    let existingRecords = [];
    let newRecords = [];

    const businessTypeObject =
      internalRecord &&
      internalRecord.businessSettings &&
      internalRecord.businessSettings.length
        ? internalRecord.businessSettings.find(tA => tA.type === type)
        : null;

    const records = (businessTypeObject && businessTypeObject.records) || [];

    if (records && records.length) {
      existingRecords = [...records];
    }

    // Check if already selected
    if (!records || !records.some(mng => mng.id === record.value)) {
      const newRecord = {
        id: record.value,
        name: record.label
      };

      newRecords = [...existingRecords, newRecord];
    } else {
      // Remove on deselect
      const filteredSelectedRecords = records.filter(
        mng => mng.id !== record.value
      );

      newRecords = filteredSelectedRecords;
    }

    const newBusinessSettings = businessTypeObject
      ? internalRecord.businessSettings.reduce((acc, curr) => {
          if (curr.type === type) {
            return [
              ...acc,
              {
                ...curr,
                records: newRecords
              }
            ];
          }
          return [...acc, curr];
        }, [])
      : [
          ...((internalRecord && internalRecord.businessSettings) || []),
          {
            type,
            records: newRecords
          }
        ];

    setInternalRecord({
      ...internalRecord,
      businessSettings: newBusinessSettings
    });
  };

  const onSelectChallengeCompleted = (record, type) => {
    const newRecord = {
      id: record.value,
      name: record.label
    };
    const newRecords = [newRecord];

    const businessTypeObject =
      internalRecord &&
      internalRecord.businessSettings &&
      internalRecord.businessSettings.length
        ? internalRecord.businessSettings.find(tA => tA.type === type)
        : null;

    const newBusinessSettings = businessTypeObject
      ? internalRecord.businessSettings.reduce((acc, curr) => {
          if (curr.type === type) {
            return [
              ...acc,
              {
                ...curr,
                records: newRecords
              }
            ];
          }
          return [...acc, curr];
        }, [])
      : [
          ...((internalRecord && internalRecord.businessSettings) || []),
          {
            type,
            records: newRecords
          }
        ];

    setInternalRecord({
      ...internalRecord,
      businessSettings: newBusinessSettings
    });
  };

  const getCompanyGoals = useLazyQuery(GET_BUSINESS_CONFIG_RECORDS, {
    fetchPolicy: 'no-cache'
  });
  const getChallengesWithCategories = useLazyQuery(
    GET_BUSINESS_CONFIG_RECORDS_WITH_PARENTS,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const fetchCompanyGoals = async (page, search) => {
    const companyGoals = await getCompanyGoals({
      variables: {
        type: businessConfigurationTypes.GOAL,
        start: 0,
        end: (page + 1) * 10,
        search
      }
    });

    if (
      companyGoals &&
      companyGoals.data &&
      companyGoals.data.getBusinessConfigRecords &&
      companyGoals.data.getBusinessConfigRecords.records &&
      companyGoals.data.getBusinessConfigRecords.records.length
    ) {
      return companyGoals.data.getBusinessConfigRecords.records.map(r => ({
        label: r.name,
        value: r.id
      }));
    }
    return [];
  };

  const fetchChallengesWithCategories = async (page, search) => {
    const challengesWithCategories = await getChallengesWithCategories({
      variables: {
        parentType: businessConfigurationTypes.CATEGORY,
        type: businessConfigurationTypes.CHALLENGE,
        start: 0,
        end: (page + 1) * 2,
        search
      }
    });

    if (
      challengesWithCategories &&
      challengesWithCategories.data &&
      challengesWithCategories.data.getBusinessConfigRecordsWithParents &&
      challengesWithCategories.data.getBusinessConfigRecordsWithParents
        .records &&
      challengesWithCategories.data.getBusinessConfigRecordsWithParents.records
        .length
    ) {
      return challengesWithCategories.data.getBusinessConfigRecordsWithParents.records.map(
        r => ({
          label: r.name,
          value: r.id,
          ...{ isParent: !r.parent }
        })
      );
    }
    return [];
  };

  return (
    <div className={styles.content}>
      <>
        <div className={styles.subtitle}>
          {businessSettingsTitles[businessConfigurationTypes.CHALLENGE].title}
          <span className={styles.requiredAsterisk}>*</span>
          <div className={styles.tooltipContainer} ref={tooltipRef}>
            <img
              className={styles.tooltipIcon}
              alt="Touchpoints tooltip"
              src={questionMark}
              onMouseEnter={() => {
                updateTooltipPosition();
              }}
              onMouseLeave={() => {
                updateTooltipPosition();
              }}
            />
            <div
              className={styles.tooltipPopup}
              style={{
                top: `${fixedTooltipPosition.top}px`,
                left: `${fixedTooltipPosition.left}px`,
                display: `${tooltipHovered ? 'block' : 'none'}`
              }}
            >
              Sharing business background will enable to perform actionable
              research leading to clear next steps in your organization.
              <div className={styles.topTooltipPopupText} />
            </div>
          </div>
        </div>
        <div className={styles.dropdownContainer}>
          <Dropdown
            customPlaceholder="Select business challenge"
            onSelectCompleted={item =>
              onSelectChallengeCompleted(
                item,
                businessConfigurationTypes.CHALLENGE
              )
            }
            searchEnabled={false}
            additionalOptionsEndOfList={[{ label: 'Other', value: 'Other' }]}
            fetchOptions={fetchChallengesWithCategories}
            loading={false}
            selectedValues={getArrayFieldValue(
              businessConfigurationTypes.CHALLENGE,
              'records'
            )}
            labelField="name"
            valueField="id"
            fixedPosition
            hasParentsValues
            keepFetchOrder
          />
        </div>
        {isOtherOptionSelected(businessConfigurationTypes.CHALLENGE) ? (
          <>
            <div className={styles.subtitle}>
              {
                businessSettingsTitles[businessConfigurationTypes.CHALLENGE]
                  .customNameTitle
              }
              <span className={styles.requiredAsterisk}>*</span>
            </div>

            <input
              className={styles.input}
              placeholder="Type here"
              value={getSingleFieldValue(
                businessConfigurationTypes.CHALLENGE,
                'recordCustomName'
              )}
              onChange={e =>
                updateInternalRecord(
                  businessConfigurationTypes.CHALLENGE,
                  'recordCustomName',
                  e.target.value
                )
              }
            />
          </>
        ) : null}
        <div className={styles.subtitle}>
          {
            businessSettingsTitles[businessConfigurationTypes.CHALLENGE]
              .descriptionTitle
          }
          <span className={styles.requiredAsterisk}>*</span>
        </div>
        <textarea
          placeholder="Type here"
          className={styles.textareaField}
          value={getSingleFieldValue(
            businessConfigurationTypes.CHALLENGE,
            'description'
          )}
          onChange={e =>
            updateInternalRecord(
              businessConfigurationTypes.CHALLENGE,
              'description',
              e.target.value
            )
          }
        />
        <div className={styles.subtitle}>
          {businessSettingsTitles[businessConfigurationTypes.GOAL].title}
          <span className={styles.requiredAsterisk}>*</span>
        </div>
        <div className={styles.dropdownContainer}>
          <Dropdown
            customPlaceholder="Select company goal(s)"
            onSelectCompleted={item =>
              onSelectGoalCompleted(item, businessConfigurationTypes.GOAL)
            }
            searchEnabled
            additionalOptionsEndOfList={[{ label: 'Other', value: 'Other' }]}
            fetchOptions={fetchCompanyGoals}
            loading={false}
            selectedValues={getArrayFieldValue(
              businessConfigurationTypes.GOAL,
              'records'
            )}
            labelField="name"
            valueField="id"
            fixedPosition
            keepFetchOrder
          />
        </div>
        {isOtherOptionSelected(businessConfigurationTypes.GOAL) ? (
          <>
            <div className={styles.subtitle}>
              {
                businessSettingsTitles[businessConfigurationTypes.GOAL]
                  .customNameTitle
              }
              <span className={styles.requiredAsterisk}>*</span>
            </div>
            <input
              className={styles.input}
              placeholder="Type here"
              value={getSingleFieldValue(
                businessConfigurationTypes.GOAL,
                'recordCustomName'
              )}
              onChange={e =>
                updateInternalRecord(
                  businessConfigurationTypes.GOAL,
                  'recordCustomName',
                  e.target.value
                )
              }
            />
          </>
        ) : null}
      </>
    </div>
  );
};
