import React, { useState, useEffect } from 'react';

import RecordSelection from './components/RecordSelection';
import textareaAutoExpand from '../../../../../helpers/textareaAutoExpand';

import arrowDownPurple from '../../../../../../assets/img/arrow-down-purple.svg';
import Icon from '../../../SurveyBuilder/components/Icon/Icon';

import styles from '../Welcome/Welcome.module.css';

export default ({ survey, isAdmin, onUpdate }) => {
  const [expanded, setExpanded] = useState(true);
  const [assumptionToValidateValue, setAssumptionToValidateValue] = useState(
    (survey && survey.scoping && survey.scoping.assumptionToValidate) || ''
  );
  const [
    targetAudienceDescriptionValue,
    setTargetAudienceDescriptionValue
  ] = useState(
    (survey && survey.scoping && survey.scoping.targetAudienceDescription) || ''
  );
  const [actionsOrDecisionsValue, setActionsOrDecisionsValue] = useState(
    (survey && survey.scoping && survey.scoping.actionsOrDecisions) || ''
  );
  const [
    unknownActionsOrDecisionsValue,
    setUnknownActionsOrDecisionsalue
  ] = useState(
    (survey && survey.scoping && survey.scoping.unknownActionsOrDecisions) ||
      false
  );
  const [assumptionTextarea, setAssumptionTextarea] = useState(null);
  const [targetAudienceTextarea, setTargetAudienceTextarea] = useState(null);
  const [actionsOrDecisionsTextarea, setActionsOrDecisionsTextarea] = useState(
    null
  );

  const onWindowResize = ev => {
    ev.preventDefault();
    if (assumptionTextarea) {
      textareaAutoExpand(assumptionTextarea);
    }

    if (targetAudienceTextarea) {
      textareaAutoExpand(targetAudienceTextarea);
    }

    if (actionsOrDecisionsTextarea) {
      textareaAutoExpand(actionsOrDecisionsTextarea);
    }
  };

  useEffect(
    () => {
      if (assumptionTextarea) {
        textareaAutoExpand(assumptionTextarea);
      }

      if (targetAudienceTextarea) {
        textareaAutoExpand(targetAudienceTextarea);
      }

      if (actionsOrDecisionsTextarea) {
        textareaAutoExpand(actionsOrDecisionsTextarea);
      }

      window.addEventListener('resize', onWindowResize);

      return () => {
        window.removeEventListener('resize', onWindowResize);
      };
    },
    [assumptionTextarea]
  );

  const prepareScopingDataForUpdate = payload => {
    const newPayload = {
      ...payload,
      ...(payload && payload.nextSteps && payload.nextSteps.length
        ? {
            nextSteps: payload.nextSteps.map(nS => ({
              id: nS._id,
              description: nS.description
            }))
          }
        : {})
    };
    delete newPayload.__typename;
    return newPayload;
  };

  const recordBusinessSettings =
    survey.recordDetails && survey.recordDetails.businessSettings
      ? JSON.parse(survey.recordDetails.businessSettings)
      : null;

  return (
    <div className={styles.container}>
      <div
        className={styles.titleContainer}
        role="presentation"
        onClick={() => setExpanded(() => setExpanded(!expanded))}
      >
        <div className={styles.title}>Scoping</div>
        <img
          src={arrowDownPurple}
          alt="ExpandColapse"
          className={expanded ? styles.arrowExpanded : styles.arrowCollapsed}
        />
      </div>
      {expanded ? (
        <div
          className={styles.detailsContainer}
          style={{ paddingBottom: '20px' }}
        >
          <RecordSelection
            survey={survey}
            isAdmin={isAdmin}
            onUpdate={onUpdate}
          />
          {recordBusinessSettings ? (
            <>
              <div className={styles.detailContainer}>
                <div className={styles.rowText}>
                  What is the business challenge you want to conduct research
                  for?
                </div>
                <div
                  className={`${styles.description} ${styles.disabled} ${
                    styles.fakeTextarea
                  }`}
                >
                  {recordBusinessSettings &&
                  recordBusinessSettings.CHALLENGE &&
                  recordBusinessSettings.CHALLENGE.recordNames &&
                  recordBusinessSettings.CHALLENGE.recordNames.length
                    ? recordBusinessSettings.CHALLENGE.recordNames.map(name => (
                        <div key={name}>{name}</div>
                      ))
                    : ''}
                </div>
                <div />
              </div>
              <div className={styles.detailContainer}>
                <div className={styles.rowText}>Business challenge context</div>
                <div
                  className={`${styles.description} ${styles.disabled} ${
                    styles.fakeTextarea
                  }`}
                  style={{ lineHeight: '20px' }}
                >
                  {(recordBusinessSettings &&
                    recordBusinessSettings.CHALLENGE &&
                    recordBusinessSettings.CHALLENGE.description) ||
                    ''}
                </div>
                <div />
              </div>
              <div className={styles.detailContainer}>
                <div className={styles.rowText}>
                  What company goal(s) are you contributing to by solving this
                  business challenge?
                </div>
                <div
                  className={`${styles.description} ${styles.disabled} ${
                    styles.fakeTextarea
                  }`}
                >
                  {recordBusinessSettings &&
                  recordBusinessSettings.GOAL &&
                  recordBusinessSettings.GOAL.recordNames &&
                  recordBusinessSettings.GOAL.recordNames.length
                    ? recordBusinessSettings.GOAL.recordNames.map(name => (
                        <div key={name}>{name}</div>
                      ))
                    : ''}
                </div>
                <div />
              </div>
            </>
          ) : (
            <>
              <div className={styles.detailContainer}>
                <div className={styles.rowText}>
                  What actions or decisions will be taken based on the outcome
                  of the research?
                </div>
                <textarea
                  className={`${styles.description} ${
                    unknownActionsOrDecisionsValue ? styles.disabled : ''
                  } `}
                  rows="1"
                  ref={element => {
                    if (element) {
                      setActionsOrDecisionsTextarea(element);
                    }
                  }}
                  placeholder="Type here"
                  onBlur={() => {
                    const newScoping = prepareScopingDataForUpdate(
                      survey.scoping
                    );
                    newScoping.actionsOrDecisions = actionsOrDecisionsValue;
                    onUpdate('scoping', newScoping);
                  }}
                  onChange={e => {
                    if (!unknownActionsOrDecisionsValue) {
                      textareaAutoExpand(actionsOrDecisionsTextarea);
                      setActionsOrDecisionsValue(e.target.value);
                    }
                  }}
                  maxLength="2000"
                  value={
                    unknownActionsOrDecisionsValue
                      ? ''
                      : actionsOrDecisionsValue
                  }
                  disabled={unknownActionsOrDecisionsValue}
                />
                <div />
              </div>
              <div className={styles.detailContainer} style={{ paddingTop: 0 }}>
                <div
                  className={styles.checkboxContainer}
                  onClick={() => {
                    const newValue = !unknownActionsOrDecisionsValue;
                    setUnknownActionsOrDecisionsalue(newValue);

                    const newScoping = prepareScopingDataForUpdate(
                      survey.scoping
                    );
                    newScoping.unknownActionsOrDecisions = newValue;
                    onUpdate('scoping', newScoping);
                  }}
                  role="presentation"
                >
                  <Icon
                    type={
                      unknownActionsOrDecisionsValue
                        ? 'checkboxChecked'
                        : 'checkboxUnChecked'
                    }
                  />
                  <span className={styles.checkboxText}>I don’t know</span>
                </div>
              </div>
            </>
          )}

          <div className={styles.detailContainer}>
            <div className={styles.rowText}>
              Explain the research goals in a few sentences. What do you want to
              learn or validate?
              <span className={styles.requiredAsterisk}>*</span>
              <div className={styles.moreInfoContainer}>
                <div className={styles.questionMark}>?</div>
                <div className={styles.tooltip}>
                  <div>
                    We aim to provide actionable decisions. However, we can only
                    offer targeted recommendations when we have a clear
                    understanding of the specific assumption(s) you intend to
                    validate.
                  </div>
                  <div style={{ marginTop: '12px' }}>
                    Good example: We worked out a concept for a new kind of
                    vegetarian meal and I want to check potential interest among
                    both vegetarians and non-vegetarians. Furthermore, I want to
                    learn how to optimise the concept.
                  </div>
                  <div style={{ marginTop: '12px' }}>
                    Poor example: When it comes to nutrition, you are more of a…
                    <ul>
                      <li>- Pescatarian (only fish)</li>
                      <li>
                        - Flexitarian (consciously alternating between meat,
                        fish, and vegetarian options)
                      </li>
                      <li>- Vegetarian (no meat and no fish)</li>
                      <li>- Vegan (no animal products)</li>
                      <li>- Pollotarian (only poultry)</li>
                      <li>
                        - Omnivore (I eat everything, no specific preferences)
                      </li>
                      <li>- Other</li>
                      <li>- None of the above</li>
                    </ul>
                  </div>
                  <div style={{ marginTop: '12px' }}>
                    It is not intended to note research questions. This is the
                    responsibility of the research designer once the scope is
                    clear.
                  </div>
                </div>
              </div>
            </div>
            <textarea
              className={styles.description}
              rows="1"
              ref={element => {
                if (element) {
                  setAssumptionTextarea(element);
                }
              }}
              placeholder="Type here"
              onBlur={() => {
                const newScoping = prepareScopingDataForUpdate(survey.scoping);
                newScoping.assumptionToValidate = assumptionToValidateValue;
                onUpdate('scoping', newScoping);
              }}
              onChange={e => {
                textareaAutoExpand(assumptionTextarea);
                setAssumptionToValidateValue(e.target.value);
              }}
              maxLength="2000"
              value={assumptionToValidateValue}
            />
            <div />
          </div>
          <div className={styles.detailContainer}>
            <div className={styles.rowText}>
              What is the target audience for the research? What is the reason
              to limit the research to this audience(s)?
              <span className={styles.requiredAsterisk}>*</span>
              <div className={styles.moreInfoContainer}>
                <div className={styles.questionMark}>?</div>
                <div className={styles.tooltip}>
                  We are looking for a general description of the target
                  audience and why that audience is important to make an
                  actionable business decision. Focusing on a specific target
                  audience is only valuable when there is an assumption for a
                  different behavior that might result in a different business
                  decision. In other words, targeting a niche audience without
                  validating your assumptions, might reduce market potential.
                </div>
              </div>
            </div>
            <textarea
              className={
                !(survey && survey.scoping)
                  ? styles.description
                  : `${styles.description} ${styles.notesField}`
              }
              rows="1"
              ref={element => {
                if (element) {
                  setTargetAudienceTextarea(element);
                }
              }}
              placeholder="Type here"
              onBlur={() => {
                const newScoping = prepareScopingDataForUpdate(survey.scoping);
                newScoping.targetAudienceDescription = targetAudienceDescriptionValue;
                onUpdate('scoping', newScoping);
              }}
              onChange={e => {
                textareaAutoExpand(targetAudienceTextarea);
                setTargetAudienceDescriptionValue(e.target.value);
              }}
              maxLength="2000"
              value={targetAudienceDescriptionValue}
            />
            <div />
          </div>
        </div>
      ) : null}
    </div>
  );
};
