import React, { useState, useEffect, useMemo } from 'react';
import { useMutation } from '@apollo/react-hooks';

import {
  CREATE_MERGE_GRAPH,
  HIDE_RESULT_GRAPHS,
  CREATE_OPPORTUNITY_GRAPH,
  UPDATE_OPPORTUNITY_GRAPH,
  CREATE_CUSTOM_GRAPH,
  UPDATE_CUSTOM_GRAPH,
  CREATE_CONCEPT_TEST_GRAPH,
  UPDATE_CONCEPT_TEST_GRAPH,
  CREATE_VALUE_SELECTION_GRAPH,
  UPDATE_VALUE_SELECTION_GRAPH,
  CREATE_IDEA_SELECTION_GRAPH,
  UPDATE_IDEA_SELECTION_GRAPH
} from '../../../../../../../graphql/Survey';

import * as mixpanel from '../../../../../../../../mixpanel';

import Icon from '../../../../../Icon';
import Loader from '../../../../../Loader/Loader';
import Templates from './Templates/Templates';

import areQuestionsCompatibleToMergeGraph from '../../helpers/areQuestionsCompatibleToMergeGraph';

import {
  templateConfigurations,
  templateTypes,
  defaultTemplateGraphName
} from '../../helpers/constants';

import onConfirmTemplateGraph from './helpers/onConfirmTemplateGraph';
import validateTemplateGraph from './helpers/validateTemplateGraph';
import getMatrixQuestions from '../../helpers/getMatrixQuestions';

import tickIcon from '../../../../../../../assets/img/tick.svg';
import closeIcon from '../../../../../../../assets/img/delete.svg';
import warningIcon from '../../../../../../../assets/img/exclamation.svg';

import styles from './EditGraphsPopup.module.css';
import { checkIfAllCookiesAllowed } from '../../../../../../../helpers/privacy';

const tabs = {
  MERGE_GRAPHS: 'MERGE_GRAPHS',
  SHOW_GRAPHS: 'SHOW_GRAPHS',
  TEMPLATES: 'TEMPLATES'
};

export default ({
  surveyId,
  onClose,
  questions,
  surveyRefetch,

  editGraphsPopup,
  setEditGraphsPopup,

  hiddenGraphs = [],
  mergedGraphs = [],

  isAdmin,
  isAllowedToExecuteRequests,
  viewToken
}) => {
  const [selectedBlocks, setSelectedBlocks] = useState([]);
  const [selectedGraphs, setSelectedGraphs] = useState([]);
  const [mergeQuestionName, setMergeQuestionName] = useState('');
  const [axisLabels, setAxisLabels] = useState({ x: '', y: '' });
  const [currentTab, setCurrentTab] = useState(tabs.TEMPLATES);
  const [currentTemplatesTab, setCurrentTemplatesTab] = useState(null);
  const [features, setFeatures] = useState([]);
  const [error, setError] = useState('');

  const parseQuestion = question => {
    let questionValue;
    try {
      questionValue = JSON.parse(question)
        .blocks.map(draftBlock => draftBlock.text)
        .join('\n');
    } catch (e) {
      questionValue = question;
    }
    return questionValue;
  };

  const questionBlocks = useMemo(
    () =>
      questions
        .filter(q => !q.only_visible_for_admins)
        .map(question => ({
          id: question.id,
          type: question.type,
          question: parseQuestion(question.question),
          globalIndex: question.globalIndex,
          formattedGlobalIndex: question.formattedGlobalIndex,
          answers: question.answers,
          name: question.name
        })),
    [questions]
  );
  const blocks = questionBlocks;

  const [createMergeGraph, { loading: createMergeGraphLoading }] = useMutation(
    CREATE_MERGE_GRAPH
  );
  const [hideResultGraphs, { loading: hideResultGraphsLoading }] = useMutation(
    HIDE_RESULT_GRAPHS
  );
  const [
    createOpportunityGraph,
    { loading: createOpportunityGraphLoading }
  ] = useMutation(CREATE_OPPORTUNITY_GRAPH);
  const [
    updateOpportunityGraph,
    { loading: updateOpportunityGraphLoading }
  ] = useMutation(UPDATE_OPPORTUNITY_GRAPH);
  const [
    createCustomGraph,
    { loading: createCustomGraphLoading }
  ] = useMutation(CREATE_CUSTOM_GRAPH);
  const [
    updateCustomGraph,
    { loading: updateCustomGraphLoading }
  ] = useMutation(UPDATE_CUSTOM_GRAPH);

  const [
    createConceptTestGraph,
    { loading: createConceptTestGraphLoading }
  ] = useMutation(CREATE_CONCEPT_TEST_GRAPH);
  const [
    updateConceptTestGraph,
    { loading: updateConceptTestGraphLoading }
  ] = useMutation(UPDATE_CONCEPT_TEST_GRAPH);

  const [
    createValueSelectionGraph,
    { loading: createValueSelectionGraphLoading }
  ] = useMutation(CREATE_VALUE_SELECTION_GRAPH);
  const [
    updateValueSelectionGraph,
    { loading: updateValueSelectionGraphLoading }
  ] = useMutation(UPDATE_VALUE_SELECTION_GRAPH);
  const [
    createIdeaSelectionGraph,
    { loading: createIdeaSelectionGraphLoading }
  ] = useMutation(CREATE_IDEA_SELECTION_GRAPH);
  const [
    updateIdeaSelectionGraph,
    { loading: updateIdeaSelectionGraphLoading }
  ] = useMutation(UPDATE_IDEA_SELECTION_GRAPH);

  const isGraphLoading =
    createOpportunityGraphLoading ||
    updateOpportunityGraphLoading ||
    createCustomGraphLoading ||
    updateCustomGraphLoading ||
    createConceptTestGraphLoading ||
    updateConceptTestGraphLoading ||
    createValueSelectionGraphLoading ||
    updateValueSelectionGraphLoading ||
    createIdeaSelectionGraphLoading ||
    updateIdeaSelectionGraphLoading;

  useEffect(
    () => {
      if (currentTab === tabs.MERGE_GRAPHS) {
        setSelectedBlocks([]);
        setSelectedGraphs([]);
      } else if (currentTab === tabs.SHOW_GRAPHS) {
        setSelectedBlocks(
          blocks
            .filter(
              b =>
                hiddenGraphs &&
                hiddenGraphs.flows &&
                hiddenGraphs.flows.indexOf(b.id) === -1
            )
            .map(b => b.id)
        );

        setSelectedGraphs(
          processHiddenGraphs(mergedGraphs, hiddenGraphs.mergedGraphs)
        );
      } else if (currentTab === tabs.TEMPLATES) {
        setSelectedBlocks([]);
        setSelectedGraphs([]);

        // Edit existing graph
        if (editGraphsPopup && editGraphsPopup.id) {
          setMergeQuestionName(editGraphsPopup.name);
          setCurrentTemplatesTab(editGraphsPopup.type);

          if (editGraphsPopup.features && editGraphsPopup.features.length) {
            const templateConfiguration =
              templateConfigurations[editGraphsPopup.type];

            let availableQuestions = questionBlocks;
            if (
              editGraphsPopup.question &&
              editGraphsPopup.features.every(f => f.choiceId)
            ) {
              availableQuestions = getMatrixQuestions(questions);
            }

            const selectedFeatures = editGraphsPopup.features.map(f => {
              const newFeature = { ...f };

              const xQuestion = availableQuestions.find(
                qB => f[templateConfiguration.xQuestionName] === qB.id
              );
              newFeature[templateConfiguration.xQuestionName] = xQuestion;

              const yQuestion = availableQuestions.find(
                qB => f[templateConfiguration.yQuestionName] === qB.id
              );
              newFeature[templateConfiguration.yQuestionName] = yQuestion;

              return newFeature;
            });
            setFeatures(selectedFeatures);
          }

          if (editGraphsPopup.type === templateTypes.CUSTOM_GRAPH) {
            setAxisLabels({
              x: editGraphsPopup.xLabel,
              y: editGraphsPopup.yLabel
            });
          }
        }

        // Create new graph
        if (editGraphsPopup === true) {
          setMergeQuestionName(defaultTemplateGraphName);
          if (editGraphsPopup.type === templateTypes.OPPORTUNITY_GRAPH) {
            setFeatures([
              {
                importanceQuestion: null,
                satisfactionQuestion: null,
                description: '',
                active: false
              }
            ]);
          } else {
            setFeatures([
              {
                xQuestion: null,
                yQuestion: null,
                description: '',
                active: false
              }
            ]);
          }
        }
      }
    },
    [currentTab]
  );

  const blocksDict = blocks.reduce((acc, b) => ({ ...acc, [b.id]: b }), {});

  const processHiddenGraphs = (original, adjustTo) =>
    original.reduce((acc, mg) => {
      // Find if there is related merged graph
      const sibling = adjustTo.find(sg => sg.graph === mg.id);

      if (sibling) {
        // Find the diff between datasets
        const filteredQuestions = mg.questions
          .filter(q => sibling.questions.indexOf(q.id) === -1)
          .map(q => q.id);

        if (filteredQuestions.length === 0) {
          // Do not include current graph is there is nothing to hide
          return [...acc];
        }

        // Return graph with differentiated questions
        return [...acc, { graph: mg.id, questions: filteredQuestions }];
      }
      // Return graph with all questions
      return [...acc, { graph: mg.id, questions: mg.questions.map(q => q.id) }];
    }, []);

  // const lastQuestionNumber = useMemo(
  //   () => Math.max(...questions.map(o => o.globalIndex || 0)),
  //   [questionBlocks]
  // );

  // const isLoading = loadingCreate || loadingUpdate || loadingDelete;
  const isLoading = false;

  const Question = ({
    isActive = true,
    questionIndex,
    label,
    icon,
    isChecked,
    onCheck,
    shift = false
  }) => (
    <div
      className={`${
        isActive
          ? styles.question
          : `${styles.question} ${styles.questionNotMergeable}`
      } ${shift && styles.shift}`}
      key={`create-workspace-${questionIndex.toString()}`}
    >
      <div className={styles.selectorContainer}>
        <span
          className={styles.selectorIcon}
          onClick={onCheck}
          role="presentation"
        >
          <Icon type={isChecked ? 'checked' : 'unchecked'} />
        </span>
      </div>
      <div className={styles.number}>{questionIndex}</div>
      <div className={styles.iconContainer}>
        <span style={{ color: '#5200f1' }}>
          <Icon type={icon} />
        </span>
      </div>
      <div className={styles.questionTextContainer}>{label}</div>
    </div>
  );

  const onBlockClick = id => {
    if (selectedBlocks.indexOf(id) > -1) {
      const newSelectedBlocks = selectedBlocks.filter(block => block !== id);
      setSelectedBlocks(newSelectedBlocks);
    } else {
      setSelectedBlocks([...selectedBlocks, id]);
    }
  };

  const tabMap = {
    MERGE_GRAPHS: {
      type: 'MERGE_GRAPHS',
      description:
        'In order to merge multiple graphs, they need to have exact same form, question type and answer options. Checking one question will reveal the possible combinations.',
      onConfirm: async () => {
        if (isAllowedToExecuteRequests) {
          await createMergeGraph({
            variables: {
              survey: surveyId,
              questions: selectedBlocks,
              name: mergeQuestionName,
              ...(viewToken ? { viewToken } : {})
            }
          });

          surveyRefetch();
        }
      },
      onClose: () => {
        onClose();
        const scrollingElement = document.scrollingElement || document.body;
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
      },
      getQuestions: () => {
        const filteredBlocks = blocks.filter(
          block => block.type === 'Multiple Choice Question'
        );

        const mergeableList = filteredBlocks.map(block =>
          areQuestionsCompatibleToMergeGraph(
            selectedBlocks,
            filteredBlocks,
            block
          )
        );

        return filteredBlocks.map((block, index) => (
          <Question
            key={block.id}
            block={block}
            index={index}
            isActive={mergeableList[index]}
            id={block.id}
            questionIndex={block.formattedGlobalIndex}
            label={block.question}
            isChecked={selectedBlocks.indexOf(block.id) > -1}
            icon={block.name === 'checkboxes' ? 'multiple-choice' : block.name}
            onCheck={() => {
              const isMergeable = areQuestionsCompatibleToMergeGraph(
                selectedBlocks,
                blocks,
                block
              );

              if (isMergeable) onBlockClick(block.id);
            }}
          />
        ));
      },
      calculateSelected: () => ({
        blocksSelected: selectedBlocks.length,
        allSelected: false
      }),
      onSelectAllClick: () => {},
      searchBar: true,
      searchBarPlaceholder: 'Name of merged graph',
      selectAllButton: false
    },
    SHOW_GRAPHS: {
      type: 'SHOW_GRAPHS',
      description:
        'Select the questions from your survey that you would like to analyze in the results summary. This can still be changed afterwards if necessary.',
      onConfirm: async () => {
        if (isAllowedToExecuteRequests) {
          await hideResultGraphs({
            variables: {
              survey: surveyId,
              flows: blocks
                .filter(b => selectedBlocks.indexOf(b.id) === -1)
                .map(b => b.id),
              mergedGraphs: processHiddenGraphs(mergedGraphs, selectedGraphs)
            }
          });
        }
      },
      onClose: () => onClose(),
      getQuestions: () => {
        // Render simple flows
        const filteredBlocks = blocks.filter(
          block => block.type !== 'Distributor'
        );

        const flows = filteredBlocks.map((block, index) => (
          <Question
            block={block}
            index={index}
            questionIndex={block.formattedGlobalIndex || block.globalIndex}
            label={block.question}
            icon={block.name === 'checkboxes' ? 'multiple-choice' : block.name}
            isChecked={selectedBlocks.indexOf(block.id) > -1}
            onCheck={() => {
              onBlockClick(block.id);
            }}
          />
        ));

        const highestNumber = () => {
          let number;
          try {
            number = Math.max(
              ...filteredBlocks.map(b =>
                Number.isInteger(b.formattedGlobalIndex)
                  ? b.formattedGlobalIndex
                  : parseInt(b.formattedGlobalIndex.replace(/\D/g, ''), 10)
              )
            );
          } catch {
            number = Math.max(...questions.map(o => o.globalIndex || 0));
          }
          return number;
        };

        // Render merged graph with questions
        const mergedGraphWithSubgraphs = mergedGraphs.map((g, i) => {
          const graphIdx = selectedGraphs.findIndex(sg => sg.graph === g.id);
          const isGraphChecked = graphIdx >= 0;

          const mergedGraph = (
            <Question
              icon="merged-graph"
              questionIndex={highestNumber() + (i + 1)}
              label={g.name}
              isChecked={isGraphChecked}
              onCheck={() => {
                if (isGraphChecked) {
                  const filteredGraphs = selectedGraphs.filter(
                    sg => sg.graph !== g.id
                  );
                  setSelectedGraphs([...filteredGraphs]);
                } else {
                  setSelectedGraphs(prev => [
                    ...prev,
                    { graph: g.id, questions: g.questions.map(q => q.id) }
                  ]);
                }
              }}
            />
          );

          const subgraphs = g.questions
            .map(q => blocksDict[q.id])
            .map((q, qi) => {
              let isQuestionChecked;
              let questionIdx = -1;

              if (isGraphChecked) {
                questionIdx = selectedGraphs[graphIdx].questions.indexOf(q.id);
                isQuestionChecked = questionIdx >= 0;
              }

              // Return each particular question for merged graph
              return (
                <Question
                  label={q.question}
                  questionIndex={`${String.fromCharCode(97 + qi)})`} // a) b) c) list format
                  icon={q.name}
                  isChecked={isQuestionChecked}
                  shift
                  onCheck={() => {
                    if (isQuestionChecked) {
                      // Remove question from array
                      const filteredQuestions = selectedGraphs[
                        graphIdx
                      ].questions.filter((_, j) => j !== questionIdx);

                      if (filteredQuestions.length === 0) {
                        const filteredGraphs = selectedGraphs.filter(
                          sg => sg.graph !== g.id
                        );
                        setSelectedGraphs([...filteredGraphs]);
                      } else {
                        selectedGraphs[graphIdx].questions = filteredQuestions;
                        setSelectedGraphs([...selectedGraphs]);
                      }
                    } else if (isGraphChecked) {
                      // Add question to array when at least 1 question is checked
                      selectedGraphs[graphIdx].questions.push(q.id);
                      setSelectedGraphs([...selectedGraphs]);
                    } else {
                      // Add first question to array, include graph
                      setSelectedGraphs(prev => [
                        ...prev,
                        { graph: g.id, questions: [q.id] }
                      ]);
                    }
                  }}
                />
              );
            });
          return [mergedGraph, ...subgraphs];
        });

        return [...flows, ...mergedGraphWithSubgraphs];
      },
      calculateSelected: () => ({
        blocksSelected: selectedGraphs.length + selectedBlocks.length,
        allSelected:
          blocks.length + mergedGraphs.length ===
          selectedGraphs.length + selectedBlocks.length
      }),
      onSelectAllClick: () => {
        const maxSum = blocks.length + mergedGraphs.length;
        const sum = selectedGraphs.length + selectedBlocks.length;

        if (sum === maxSum) {
          setSelectedBlocks([]);
          setSelectedGraphs([]);
        } else {
          setSelectedBlocks(blocks.map(block => block.id));
          setSelectedGraphs(
            mergedGraphs.map(mg => ({
              graph: mg.id,
              questions: mg.questions.map(q => q.id)
            }))
          );
        }
      },
      searchBar: false,
      searchBarPlaceholder: '',
      selectAllButton: true
    },
    TEMPLATES: {
      type: 'TEMPLATES',
      description: null,
      onConfirm: async () => {
        const templateConfiguration =
          templateConfigurations[currentTemplatesTab];
        const errorExists = validateTemplateGraph(
          features,
          mergeQuestionName,
          setError,
          templateTypes,
          currentTemplatesTab,
          templateConfiguration
        );

        const graphqlRequests = {
          createOpportunityGraph,
          updateOpportunityGraph,
          createCustomGraph,
          updateCustomGraph,
          createConceptTestGraph,
          updateConceptTestGraph,
          createValueSelectionGraph,
          updateValueSelectionGraph,
          createIdeaSelectionGraph,
          updateIdeaSelectionGraph
        };

        if (isAllowedToExecuteRequests && !errorExists) {
          await onConfirmTemplateGraph(
            surveyId,
            editGraphsPopup,
            features,
            mergeQuestionName,
            axisLabels,
            graphqlRequests,
            setError,
            onClose,
            templateTypes,
            currentTemplatesTab,
            templateConfiguration,
            surveyRefetch,
            setEditGraphsPopup,
            viewToken
          );
          if (checkIfAllCookiesAllowed()) {
            mixpanel.actions.trackEvent(
              mixpanel.events.CLIENT_CREATE_DECISION_FRAMEWORK,
              {
                [mixpanel.eventProperties.SURVEY_ID]: surveyId,
                [mixpanel.eventProperties
                  .DECISION_FRAMEWORK_TYPE]: currentTemplatesTab,
                [mixpanel.eventProperties
                  .DECISION_FRAMEWORK_NAME]: mergeQuestionName
              }
            );
          }
        }
      },
      onClose: () => {
        onClose();
        const scrollingElement = document.scrollingElement || document.body;
        scrollingElement.scrollTop = scrollingElement.scrollHeight;
      },
      getQuestions: () => {},
      calculateSelected: () => ({}),
      onSelectAllClick: () => {},
      searchBar: true,
      searchBarPlaceholder: 'Graph name',
      selectAllButton: false
    }
  };

  const { blocksSelected, allSelected } = useMemo(
    () => tabMap[currentTab].calculateSelected(),
    [currentTab, selectedBlocks, selectedGraphs]
  );

  const resetValuesOnTabSwitch = () => {
    if (error) setError('');
    if (editGraphsPopup) {
      setFeatures([]);
    }
    if (mergeQuestionName) setMergeQuestionName();
  };

  return (
    <div className={styles.container}>
      <div className={styles.titleContainer}>
        <div
          className={`${styles.titleItem} ${
            currentTab === tabs.TEMPLATES ? styles.activeTitle : ''
          }`}
          role="presentation"
          onClick={() => {
            setCurrentTab(tabs.TEMPLATES);
            resetValuesOnTabSwitch();
          }}
        >
          Decision frameworks
        </div>
        <div
          className={`${styles.titleItem} ${
            currentTab === tabs.MERGE_GRAPHS ? styles.activeTitle : ''
          }`}
          role="presentation"
          onClick={() => {
            setSelectedBlocks([]);
            setCurrentTab(tabs.MERGE_GRAPHS);
            resetValuesOnTabSwitch();
          }}
        >
          Merge questions
        </div>
        <div
          className={`${styles.titleItem} ${
            currentTab === tabs.SHOW_GRAPHS ? styles.activeTitle : ''
          }`}
          role="presentation"
          onClick={() => {
            setCurrentTab(tabs.SHOW_GRAPHS);
            resetValuesOnTabSwitch();
          }}
        >
          Hide questions
        </div>
        <div className={styles.titleItemPlaceholder} />
      </div>

      {currentTab === tabs.TEMPLATES ? (
        <Templates
          templateTypes={templateTypes}
          tabProperties={tabMap[currentTab]}
          mergeQuestionName={mergeQuestionName}
          setMergeQuestionName={setMergeQuestionName}
          questions={questions}
          questionBlocks={questionBlocks}
          features={features}
          setFeatures={setFeatures}
          currentTemplatesTab={currentTemplatesTab}
          setCurrentTemplatesTab={setCurrentTemplatesTab}
          editGraphsPopup={editGraphsPopup}
          templateConfigurations={templateConfigurations}
          axisLabels={axisLabels}
          setAxisLabels={setAxisLabels}
          isAdmin={isAdmin}
        />
      ) : null}

      {currentTab === tabs.MERGE_GRAPHS || currentTab === tabs.SHOW_GRAPHS ? (
        <>
          <div className={styles.description}>
            {tabMap[currentTab].description}
          </div>
          {tabMap[currentTab].searchBar && (
            <div className={styles.graphNameContainer}>
              <input
                className={styles.mergedGraphName}
                type="text"
                placeholder={tabMap[currentTab].searchBarPlaceholder}
                value={mergeQuestionName}
                onChange={e => setMergeQuestionName(e.target.value)}
              />
            </div>
          )}
          <div className={styles.selectAllContainer}>
            <div className={styles.selectorContainer}>
              {tabMap[currentTab].selectAllButton && (
                <span
                  onClick={() => tabMap[currentTab].onSelectAllClick()}
                  role="presentation"
                >
                  <Icon type={allSelected ? 'checked' : 'unchecked'} />
                </span>
              )}
            </div>
            <span className={styles.selectedBlocks}>
              {blocksSelected} {blocksSelected === 1 ? 'block' : 'blocks'}{' '}
              selected
            </span>
          </div>
          <div className={styles.questionsContainer}>
            {blocks && tabMap[currentTab].getQuestions()}
          </div>
        </>
      ) : null}

      <div className={styles.footer}>
        {error.length ? (
          <>
            <div className={styles.footerErrorBackground} />
            <div className={styles.error}>
              <img
                className={styles.warningIcon}
                src={warningIcon}
                alt="Warning icon"
              />
              {error}
            </div>
          </>
        ) : (
          <div className={styles.details} />
        )}
        {isLoading ? (
          <div className={styles.actions}>
            <Loader size="small" />
          </div>
        ) : (
          <div className={styles.actions}>
            {(blocksSelected > 0 &&
              !createMergeGraphLoading &&
              !hideResultGraphsLoading) ||
            (currentTab === tabs.TEMPLATES &&
              features.length &&
              !isGraphLoading) ? (
              <img
                className={styles.confirm}
                src={tickIcon}
                alt="Confirm icon"
                onClick={() => {
                  if (!isLoading) {
                    tabMap[currentTab].onConfirm();
                  }
                  if (currentTab !== tabs.TEMPLATES)
                    tabMap[currentTab].onClose();
                }}
                role="presentation"
              />
            ) : null}
            {selectedBlocks &&
            selectedBlocks.length &&
            (createMergeGraphLoading ||
              hideResultGraphsLoading ||
              isGraphLoading) ? (
              <div className={styles.loaderContainer}>
                <Loader size="small" />
              </div>
            ) : null}
            <img
              className={styles.close}
              src={closeIcon}
              alt="Close icon"
              onClick={() => onClose()}
              role="presentation"
            />
          </div>
        )}
      </div>
    </div>
  );
};
