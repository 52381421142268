import React, { useState, useEffect } from 'react';

import AccountManagementRecordSelect from '../../../../../../../base/components/AccountManagementRecordSelect/AccountManagementRecordSelect';
import { stepTypes } from '../../../../PublishSurvey/PublishSurvey';

import styles from '../../Welcome/Welcome.module.css';

export default ({ survey, isAdmin, onUpdate }) => {
  const [record, setRecord] = useState({
    selectedAccountManagementRecord: {
      label:
        (survey && survey.recordDetails && survey.recordDetails.name) ||
        undefined,
      value:
        (survey && survey.recordDetails && survey.recordDetails.id) || undefined
    }
  });
  const [recordCustomName, setRecordCustomName] = useState(
    (survey && survey.recordCustomName) || ''
  );

  useEffect(
    () => {
      if (
        record &&
        record.selectedAccountManagementRecord &&
        !record.selectedAccountManagementRecord.value &&
        recordCustomName &&
        recordCustomName !== ''
      ) {
        setRecord({
          selectedAccountManagementRecord: {
            label: 'No account',
            value: 'No account'
          }
        });
      }
    },
    [survey.recordCustomName]
  );

  const surveyOncePublished =
    survey &&
    survey.history &&
    survey.history.length &&
    survey.history.some(h => h.type === stepTypes.PUBLISH_SURVEY);

  return (
    <>
      <div
        className={`${styles.detailContainer} ${
          styles.welcomeAccountManagmenetRecordSelectContainer
        }`}
      >
        <div className={styles.rowText}>
          Customer name or business challenge
          <span className={styles.requiredAsterisk}>*</span>
        </div>
        <AccountManagementRecordSelect
          accountManagement={record}
          onSelect={async recordId => {
            if (recordId.value === 'No account') {
              setRecord({
                selectedAccountManagementRecord: {
                  value: 'No account',
                  label: 'No account'
                }
              });
              await onUpdate('record', null);
            } else {
              setRecord({
                selectedAccountManagementRecord: {
                  value: recordId.id,
                  label: recordId.name
                }
              });
              await onUpdate('record', recordId.id);
            }

            // Remove linked surveys when AM record is changed
            const recordValueChanged =
              record &&
              record.selectedAccountManagementRecord &&
              record.selectedAccountManagementRecord.value &&
              record.selectedAccountManagementRecord.value !== recordId.value;
            if (
              recordValueChanged &&
              survey.translationOfOtherSurvey &&
              survey.relatedSurveys &&
              survey.relatedSurveys.length
            ) {
              onUpdate('relatedSurveys', []);
            }
          }}
          isAdmin={isAdmin}
          surveyAssignDropdown
          hideInfoText
          filterDropdownStyle={styles.welcomeFilterDropdownStyle}
          disabled={surveyOncePublished}
        />
      </div>
      {record &&
      record.selectedAccountManagementRecord &&
      record.selectedAccountManagementRecord.value === 'No account' ? (
        <div className={styles.detailContainer}>
          <div className={styles.rowText}>
            Customer name<span className={styles.requiredAsterisk}>*</span>
          </div>
          <textarea
            className={styles.surveyName}
            rows="1"
            placeholder="Type here"
            onBlur={() => onUpdate('recordCustomName', recordCustomName)}
            onChange={e => setRecordCustomName(e.target.value)}
            maxLength="1000"
            value={recordCustomName}
            disabled={surveyOncePublished}
          />
        </div>
      ) : null}
    </>
  );
};
