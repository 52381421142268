import React, { useState, useEffect, useRef } from 'react';

import Icon from '../../../../campaigns/components/Icon';
import Loader from '../../../../campaigns/components/Loader/Loader';
import DebounceInput from '../../../../campaigns/components/DebounceInput/DebounceInput';

import arrowDown from '../../../../assets/img/arrow-down.svg';

import styles from './Dropdown.module.css';

const Dropdown = props => {
  const {
    onSelectCompleted,
    searchEnabled,
    options,
    additionalOptionsEndOfList,
    fetchOptions,
    customPlaceholder,
    selectedValues,
    customBackgroundStyle,
    customContainerStyle,
    disabled,
    labelField = 'label',
    valueField = 'value',
    fixedPosition,
    hasParentsValues = false,
    keepFetchOrder = false
  } = props;

  const [showPopup, setShowPopup] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [page, setPage] = useState(0);
  const [isMaxReached, setIsMaxReached] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const [availableOptions, setAvailableOptions] = useState(options || []);
  const [parentWidth, setParentWidth] = useState(300);
  const [topPosition, setTopPosition] = useState(-1000); // hacky but works
  const [leftPosition, setLeftPosition] = useState(-1000); // hacky but works

  const dropDownRef = useRef(null);

  const placeholder = customPlaceholder || 'Select option';

  const handleClickOutside = event => {
    if (
      !(
        dropDownRef &&
        dropDownRef.current &&
        dropDownRef.current.children &&
        dropDownRef.current.children.length &&
        Array.from(dropDownRef.current.children).filter(child =>
          child.contains(event.target)
        ).length
      )
    ) {
      setShowPopup(false);
    }
  };

  const onScroll = e => {
    const isDropdownPopup =
      e &&
      e.target &&
      e.target.className &&
      e.target.className.toString() &&
      e.target.className.toString().includes('Dropdown_popup'); // hacky, but I do not see a better way to solve it now

    if (!isDropdownPopup && showPopup) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(
    () => {
      if (showPopup) {
        fetchOptionsExecute(0);
      } else if (fetchOptions) {
        emptyState();
      }

      if (fixedPosition) {
        if (dropDownRef && dropDownRef.current) {
          const height = dropDownRef.current.offsetHeight;
          const rect = dropDownRef.current.getBoundingClientRect();
          const win = dropDownRef.current.ownerDocument.defaultView;

          setParentWidth(dropDownRef.current.offsetWidth);
          setTopPosition(rect.top + win.pageYOffset - window.scrollY + height);
          setLeftPosition(rect.left + win.pageXOffset - window.scrollX);
        }

        if (showPopup) {
          document.addEventListener('wheel', onScroll, {
            passive: false
          });
        } else {
          document.removeEventListener('wheel', onScroll, {
            passive: false
          });
        }
      }
    },
    [showPopup]
  );

  const listOptionsDivScroll = async event => {
    const elem = event.currentTarget;
    if (elem.scrollHeight - elem.scrollTop - 5 <= elem.offsetHeight) {
      fetchOptionsExecute(page + 1, inputSearch);
    }
  };

  const onInputSearchChangeValue = searchValue => {
    setIsMaxReached(false);
    setInputSearch(searchValue);
    fetchOptionsExecute(0, searchValue, true);
  };

  const fetchOptionsExecute = (targetPage, inputFieldSearch, forceSearch) => {
    const runFetchOptions = async () => {
      const searchString =
        inputFieldSearch || inputFieldSearch === ''
          ? inputFieldSearch
          : inputSearch;
      setPage(targetPage);
      let fetchedOptions = await fetchOptions(targetPage, searchString);

      if (
        availableOptions &&
        fetchedOptions &&
        availableOptions.length === fetchedOptions.length
      ) {
        setIsMaxReached(true);
        // Show other option at end if max reached
        if (additionalOptionsEndOfList && additionalOptionsEndOfList.length) {
          fetchedOptions = [
            ...(fetchedOptions || []),
            ...additionalOptionsEndOfList
          ];
        }
      }

      let selectedOptions =
        selectedValues && selectedValues.length
          ? selectedValues.map(m => ({
              label: m[labelField],
              value: m[valueField]
            }))
          : [];

      // Filter selected option based on search
      if (searchString && searchString !== '') {
        selectedOptions = selectedOptions.filter(sO =>
          sO.label.includes(searchString)
        );
      }

      // Remove already selected
      if (!keepFetchOrder) {
        const newAvailableOptions = fetchedOptions.filter(
          aC => !selectedOptions.some(c => c.value === aC.value)
        );

        fetchedOptions = [...selectedOptions, ...newAvailableOptions];
      }

      setAvailableOptions(fetchedOptions);
      setIsFetching(false);
    };

    if (
      fetchOptions &&
      !isFetching &&
      (!isMaxReached || forceSearch === true)
    ) {
      setIsFetching(true);
      runFetchOptions();
    }
  };

  const emptyState = () => {
    setPage(0);
    setInputSearch('');
    setAvailableOptions([]);
    setIsMaxReached(false);
  };

  const itemIsSelected = value =>
    selectedValues &&
    selectedValues.length &&
    selectedValues.some(c => c[valueField] === value);

  const getSelectedValueLabel = () => {
    if (selectedValues && selectedValues.length === 1) {
      return selectedValues[0][labelField];
    }

    return `${selectedValues.length} items selected`;
  };

  return (
    <div
      className={`${styles.container} ${customContainerStyle}`}
      ref={dropDownRef}
    >
      <div
        role="presentation"
        className={`${styles.selectedValue}`}
        style={{
          backgroundImage: `url(${arrowDown})`,
          ...customBackgroundStyle
        }}
        onClick={() => {
          if (!disabled) {
            setShowPopup(!showPopup);
          }
        }}
      >
        {!selectedValues || !selectedValues.length ? (
          <span className={styles.placeholder}>{placeholder}</span>
        ) : (
          <span>{getSelectedValueLabel()}</span>
        )}
      </div>
      {showPopup ? (
        <div
          className={styles.popupContainer}
          style={
            fixedPosition
              ? {
                  position: 'fixed',
                  width: `${parentWidth}px`,
                  left: `${leftPosition}px`,
                  top: `${topPosition + 4}px`
                }
              : {}
          }
        >
          {searchEnabled && (
            <div className={styles.searchInput}>
              <DebounceInput
                initialValue={inputSearch}
                onChangeValue={searchValue =>
                  onInputSearchChangeValue(searchValue)
                }
                placeholder="Search"
              />
              {isFetching && availableOptions.length > 0 ? (
                <div className={styles.inputLoaderContainer}>
                  <Loader size="small" />
                </div>
              ) : null}
            </div>
          )}
          <div
            className={styles.popupItemsContainer}
            onScroll={listOptionsDivScroll}
          >
            {availableOptions && availableOptions.length && !disabled
              ? availableOptions.map((item, index) => (
                  <div
                    className={`${styles.popupItem}
                    ${
                      hasParentsValues &&
                      !(item.isParent || item.value === 'Other')
                        ? styles.childPopupItem
                        : ''
                    }
                    ${
                      hasParentsValues &&
                      (item.isParent || item.value === 'Other')
                        ? styles.parentPopupItem
                        : ''
                    }`}
                    key={`filter-dropdown-item-${index.toString()}`}
                    role="presentation"
                    onClick={() => {
                      if (
                        !(hasParentsValues && item.isParent) ||
                        item.value === 'Other'
                      ) {
                        onSelectCompleted(item);
                        if (!searchEnabled) {
                          setShowPopup(false);
                        }
                      }
                    }}
                  >
                    {searchEnabled && (
                      <span
                        className={`${
                          itemIsSelected(item.value)
                            ? styles.checkboxSelected
                            : styles.checkbox
                        } check-icon`}
                      >
                        <Icon
                          type={
                            itemIsSelected(item.value) ? 'checked' : 'unchecked'
                          }
                        />
                      </span>
                    )}
                    <div className={styles.itemLabel}>{item.label}</div>
                  </div>
                ))
              : null}

            {!isFetching &&
            (!availableOptions ||
              (availableOptions && availableOptions.length === 0)) ? (
              <div className={styles.noItems}>No search results</div>
            ) : null}
            {isFetching && availableOptions.length === 0 ? (
              <div className={styles.loaderContainer}>
                <Loader size="small" />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Dropdown;
