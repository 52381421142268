import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import Loader from '../../../base/components/Loader/Loader';

import { invitationTypes } from '../../../portals/AccountManagement/helpers/constants';

import bufflLogo from '../../../assets/img/buffl.svg';

import styles from './ClientsInvitation.module.css';

import { GET_INVITATION_DETAILS } from '../../../graphql/AccountManagement';

export default props => {
  const {
    match: {
      params: { token }
    },
    onLogout
  } = props;

  const {
    data: {
      getInvitationDetails: { clientEmail, higherRecords, invitationType } = {}
    } = {},
    loading
  } = useQuery(GET_INVITATION_DETAILS, {
    variables: {
      token
    },
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true
  });

  useEffect(() => {
    onLogout();
  }, []);

  const customerName =
    higherRecords &&
    higherRecords.length &&
    higherRecords.some(hR => hR.level === 1)
      ? higherRecords.find(hR => hR.level === 1).name
      : null;

  const teamName =
    higherRecords &&
    higherRecords.length &&
    higherRecords.some(hR => hR.level === 2)
      ? higherRecords.find(hR => hR.level === 2).name
      : null;

  let invitationExplanation = null;
  switch (invitationType) {
    case invitationTypes.SPOC:
      invitationExplanation = `as a SPOC (main person of contact within company)`;
      break;
    case invitationTypes.TEAM_LEAD:
      invitationExplanation = `as a person to involve in key decision moments of the ${teamName} business challenge`;
      break;
    case invitationTypes.MEMBER:
      invitationExplanation = `as a member of the ${teamName} business challenge`;
      break;
    default:
      invitationExplanation = null;
  }

  if (loading) {
    return (
      <div className={styles.mainLoadingContainer}>
        <Loader isProcessing />
      </div>
    );
  }

  if (!clientEmail || !invitationType) {
    return (
      <div className={styles.container}>
        <div className={styles.mainContainer}>
          <img src={bufflLogo} alt="BUFFL Logo" className={styles.logo} />
          <div className={styles.invitationContent}>
            <div className={styles.title}>Invitation not valid.</div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.mainContainer}>
        <img src={bufflLogo} alt="BUFFL Logo" className={styles.logo} />
        <div className={styles.invitationContent}>
          <div className={styles.title}>
            {clientEmail} invited you to join <span>{customerName}</span>
          </div>
          <div className={styles.invitationExplanation}>
            {invitationExplanation}
          </div>
          <div className={styles.text}>
            Create an account or log in to accept this invitation.
          </div>
          <Link to={`/auth/register?token=${token}`} className={styles.button}>
            Create an account
          </Link>
          <div className={styles.text}>
            Already have an account?{' '}
            <Link to={`/auth/login?token=${token}`}>Log in</Link>
          </div>
        </div>
      </div>
    </div>
  );
};
