import React, { useState } from 'react';

import Dropdown from '../../../AccountManagement/components/Dropdown/Dropdown';
import Loader from '../../../../campaigns/components/Loader/Loader';

import { BUSINESS_CONFIGURATION_TYPES } from '../../helpers/constants';

import amWarning from '../../../../assets/img/accountmanagement/am-warning.svg';
import tickIcon from '../../../../assets/img/tick.svg';
import closeIcon from '../../../../assets/img/delete.svg';

import styles from './ConfigurationPopup.module.css';

const ConfigurationPopup = ({
  record,
  title,
  description,
  fields,
  onSubmit,
  onClose,
  validate = () => true
}) => {
  const [internalRecord, setInternalRecord] = useState(
    record ? { ...record } : {}
  );
  const [error, setError] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);

  const isValid = validate(internalRecord);

  const fetchParentOptions = async (page, search, field) => {
    const result =
      field && field.fetchParentFunction
        ? await field.fetchParentFunction({
            variables: {
              start: 0,
              end: (page + 1) * 10,
              search,
              type: BUSINESS_CONFIGURATION_TYPES.CATEGORY
            }
          })
        : null;

    if (result && result.error) {
      setError(prevErrors => [...prevErrors, result.error]);
    } else if (result.records && result.records.length) {
      return result.records.map(p => ({ label: p.name, value: p.id }));
    }

    return [];
  };

  const onSelectParentCompleted = parent => {
    setInternalRecord({
      ...internalRecord,
      parent
    });
  };

  const returnField = f => {
    if (f.type === 'input') {
      return (
        <div key={f.fieldName}>
          {f.label ? <div className={styles.label}>{f.label}</div> : null}
          <input
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            className={styles.input}
            placeholder={f.placeholder}
            value={
              internalRecord && f.fieldName && internalRecord[f.fieldName]
                ? internalRecord[f.fieldName]
                : ''
            }
            onChange={e =>
              setInternalRecord({
                ...internalRecord,
                [f.fieldName]: e.target.value
              })
            }
          />
        </div>
      );
    }

    if (f.type === 'dropdown') {
      return (
        <div key={f.fieldName}>
          {f.label ? <div className={styles.label}>{f.label}</div> : null}
          <Dropdown
            customBackgroundStyle={{ paddingLeft: '10px' }}
            customPlaceholder={f.placeholder}
            onSelectCompleted={onSelectParentCompleted}
            searchEnabled={false}
            fetchOptions={(page, search) => fetchParentOptions(page, search, f)}
            loading={false}
            selectedValues={
              internalRecord && internalRecord.parent
                ? [internalRecord.parent]
                : []
            }
            labelField="label"
            valueField="value"
            fixedPosition
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.contentContainer}>
        {description ? (
          <div className={styles.description}>{description}</div>
        ) : null}
        {fields && fields.length ? fields.map(f => returnField(f)) : null}
      </div>
      {error ? (
        <div className={styles.errorContainer}>
          <img
            className={styles.warningErrorIcon}
            alt="Touchpoints tooltip"
            src={amWarning}
          />
          <span className={styles.errorText}>{error}</span>
        </div>
      ) : null}
      <div className={styles.footer}>
        <div />
        <div className={styles.actions}>
          {submitLoading && (
            <div className={styles.loaderContainer}>
              <Loader size="small" />
            </div>
          )}
          {!submitLoading && [
            isValid ? (
              <img
                key="submit-button"
                className={`${
                  styles.confirm
                } workspaces-manage-workspace-confirm-button`}
                src={tickIcon}
                alt="Confirm icon"
                onClick={async () => {
                  setSubmitLoading(true);
                  const result = await onSubmit(internalRecord, record);

                  if (result && result.error) {
                    setError(result.error);
                  }

                  setSubmitLoading(false);
                }}
                role="presentation"
              />
            ) : (
              <img
                key="submit-button"
                className={`${styles.confirm} ${
                  styles.invalid
                } workspaces-manage-workspace-confirm-button`}
                src={tickIcon}
                alt="Confirm icon"
              />
            ),
            <img
              key="close-button"
              className={styles.close}
              src={closeIcon}
              alt="Close icon"
              onClick={() => onClose()}
              role="presentation"
            />
          ]}
        </div>
      </div>
    </div>
  );
};

export default ConfigurationPopup;
