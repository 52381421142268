import React from 'react';

import addIcon from '../../../../../../../../assets/img/add.svg';

import styles from './ConfigurationActions.module.css';

const ConfigurationActions = props => {
  const { addActions, loading } = props;

  return (
    <div className={styles.actionButtons}>
      {Object.values(addActions).map(a => (
        <div
          className={`${styles.actionButton} ${
            loading ? styles.disabledButton : ''
          }`}
          key={a.label}
          onClick={() => {
            if (!loading) {
              a.setPopupAction();
            }
          }}
          role="presentation"
        >
          <img src={addIcon} className={styles.addIcon} alt="+" />
          <span className={styles.buttonText}>{a.label}</span>
        </div>
      ))}
    </div>
  );
};
export default ConfigurationActions;
