import React from 'react';

import {
  invitationTypes,
  businessConfigurationTypes
} from './helpers/constants';

import AccountManagementRecordsPage from './components/AccountManagementRecordsPage/AccountManagementRecordsPage';
import AccountManagementMembersPage from './components/AccountManagementMembersPage/AccountManagementMembersPage';

export default props => {
  const {
    match: {
      params: { customerId, teamId }
    },
    isAdmin
  } = props;

  const goToRecordRoute = (history, higherRecords, styles) => {
    const goToRecord = r => {
      if (r.level === 1) {
        history.push(`/account-management/${r.id}`);
      }
      if (r.level === 2) {
        const firstLevel = higherRecords.find(hR => hR.level === 1);
        history.push(`/account-management/${firstLevel.id}/${r.id}`);
      }
    };

    if (!higherRecords) return null;

    const sortedHigherRecords = [...higherRecords];
    sortedHigherRecords.sort((a, b) => a.level - b.level);

    let routes = sortedHigherRecords.map((r, i) => (
      <span
        key={`management-routes-${i.toString()}`}
        className={styles.appBarPath}
        role="presentation"
        onClick={() => goToRecord(r)}
      >
        {r.name}
        {sortedHigherRecords.length > 1 &&
        i < sortedHigherRecords.length - 1 ? (
          <span className={styles.appBarSpliter}>{'>'}</span>
        ) : (
          ''
        )}
      </span>
    ));

    // TOOD: Show it to admin only
    routes = [
      <span
        key="management-routes-customers"
        className={styles.appBarPath}
        role="presentation"
        onClick={() => {
          history.push('/account-management');
        }}
      >
        {isAdmin ? 'Customers' : 'My subscription'}
      </span>,
      <span key="customers-splitter" className={styles.appBarSpliter}>
        {'>'}
      </span>,
      ...routes
    ];

    return <span>{routes}</span>;
  };

  return (
    <>
      {customerId && teamId && (
        <AccountManagementMembersPage
          parent={teamId}
          recordsPerPage={20}
          onAddButtonLabel="New member"
          addPopupTitle="New member"
          addPopupSubtitle2="Member(s)"
          removePopupTitle="Remove member"
          inviteText="Invite with link (expires after 1 week)"
          goToRecordRoute={goToRecordRoute}
          invitationType={invitationTypes.MEMBER}
          {...props}
        />
      )}
      {customerId && !teamId && (
        <AccountManagementRecordsPage
          level={2}
          parent={customerId}
          recordsPerPage={20}
          onAddButtonLabel="New business challenge"
          addPopupTitle="New business challenge"
          addPopupSubtitle1="Give your business challenge a name"
          addPopupSubtitle2="Who to involve in key decision moments?"
          editPopupTitle="Edit business challenge"
          removePopupTitle="Remove business challenge"
          businessSettingsTitles={{
            [businessConfigurationTypes.CHALLENGE]: {
              title:
                'What is the business challenge you want to conduct research for?',
              customNameTitle: 'Other business challenge',
              descriptionTitle: 'Explain your business challenge'
            },
            [businessConfigurationTypes.GOAL]: {
              title:
                'What company goal(s) are you contributing to by solving this business challenge?',
              customNameTitle: 'Other company goal(s)'
            }
          }}
          inviteText="Invite person(s) to involve in key decision moments (link expires after 1 week)"
          goToRecordRoute={goToRecordRoute}
          invitationType={invitationTypes.TEAM_LEAD}
          {...props}
        />
      )}
      {!customerId && !teamId && (
        <AccountManagementRecordsPage
          level={1}
          recordsPerPage={20}
          title={isAdmin ? 'Customers' : 'My subscription'}
          onAddButtonLabel="New customer"
          addPopupTitle="New customer"
          addPopupSubtitle1="Customer name"
          addPopupSubtitle2="SPOC (main contact person within company)"
          editPopupTitle="Edit customer"
          removePopupTitle="Remove customer"
          inviteText="Invite SPOC with link (expires after 1 week)"
          goToRecordRoute={goToRecordRoute}
          invitationType={invitationTypes.SPOC}
          {...props}
        />
      )}
    </>
  );
};
