import gql from 'graphql-tag';

export const GET_ACCOUNT_MANAGEMENT_RECORDS = permissionFields => gql`
  query GetAccountManagementRecords(
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
    $parent: ID
    $sorting: AccountManagementRecordsSortingInput
  ) {
    getAccountManagementRecords(
      input: { start: $start, end: $end, search: $search, parent: $parent, sorting: $sorting }
    ) {
      totalCount
      records {
        id
        name
        managers {
          id
          email
        }
        licence
        licenceStartDate
        licenceEndDate
        automaticallyExtendLicence
        surveysPublished
        budgetAvailable
        ${permissionFields}
        businessSettings {
          type
          records {
            id
            name
          }
          recordOtherOptionSelected
          recordCustomName
          description
        }
      }
      higherRecords {
        id
        name
        level
        licenceStartDate
        licenceEndDate
      }
    }
  }
`;

export const CREATE_ACCOUNT_MANAGEMENT_RECORD = gql`
  mutation CreateAccountManagementRecord(
    $id: ID!
    $name: String!
    $managers: [ID]
    $level: PositiveInt!
    $parent: ID
    $licence: Boolean
    $licenceStartDate: DateTime
    $licenceEndDate: DateTime
    $automaticallyExtendLicence: Boolean
    $permissions: AccountManagementPermissionsInput
    $businessSettings: [AccountManagementBusinessRecordInput]
  ) {
    createAccountManagementRecord(
      input: {
        id: $id
        name: $name
        managers: $managers
        level: $level
        parent: $parent
        licence: $licence
        licenceStartDate: $licenceStartDate
        licenceEndDate: $licenceEndDate
        automaticallyExtendLicence: $automaticallyExtendLicence
        permissions: $permissions
        businessSettings: $businessSettings
      }
    ) {
      done
      errors {
        field
        message
      }
    }
  }
`;

export const UPDATE_ACCOUNT_MANAGEMENT_RECORD = gql`
  mutation UpdateAccountManagementRecord(
    $id: ID!
    $name: String!
    $managers: [ID]
    $licence: Boolean
    $licenceStartDate: DateTime
    $licenceEndDate: DateTime
    $automaticallyExtendLicence: Boolean
    $permissions: AccountManagementPermissionsInput
    $businessSettings: [AccountManagementBusinessRecordInput]
  ) {
    updateAccountManagementRecord(
      input: {
        id: $id
        name: $name
        managers: $managers
        licence: $licence
        licenceStartDate: $licenceStartDate
        licenceEndDate: $licenceEndDate
        automaticallyExtendLicence: $automaticallyExtendLicence
        permissions: $permissions
        businessSettings: $businessSettings
      }
    ) {
      done
      errors {
        field
        message
      }
    }
  }
`;

export const REMOVE_ACCOUNT_MANAGEMENT_RECORD = gql`
  mutation RemoveAccountManagementRecord($id: ID!) {
    removeAccountManagementRecord(input: { id: $id }) {
      done
      errors {
        field
        message
      }
    }
  }
`;

export const GET_INVITATION_LINK = gql`
  query GetInvitationLink(
    $record: ID!
    $invitationType: AccountManagementInvitationType!
  ) {
    getInvitationLink(
      input: { record: $record, invitationType: $invitationType }
    ) {
      link
      errors {
        field
        message
      }
    }
  }
`;

export const GET_INVITATION_DETAILS = gql`
  query GetInvitationDetails($token: String!) {
    getInvitationDetails(input: { token: $token }) {
      clientId
      clientEmail
      record
      higherRecords {
        id
        name
        level
      }
      invitationType
      errors {
        field
        message
      }
    }
  }
`;

export const GET_CLIENT_ACCOUNTS_TO_INVITE = gql`
  query GetClientAccountsToInvite(
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
  ) {
    getClientAccountsToInvite(
      input: { start: $start, end: $end, search: $search }
    ) {
      maxCount
      clients {
        id
        email
      }
      errors {
        field
        message
      }
    }
  }
`;

export const GET_ACCOUNT_MANAGEMENT_MEMBERS = gql`
  query GetAccountManagementMembers(
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
    $parent: ID!
  ) {
    getAccountManagementMembers(
      input: { start: $start, end: $end, search: $search, parent: $parent }
    ) {
      totalCount
      records {
        id
        name
        isManager
      }
      higherRecords {
        id
        name
        level
      }
    }
  }
`;

export const CREATE_ACCOUNT_MANAGEMENT_MEMBER = gql`
  mutation CreateAccountManagementMember($members: [ID!]!, $parent: ID) {
    createAccountManagementMember(
      input: { members: $members, parent: $parent }
    ) {
      done
      errors {
        field
        message
      }
    }
  }
`;

export const REMOVE_ACCOUNT_MANAGEMENT_MEMBER = gql`
  mutation RemoveAccountManagementMember($id: ID!, $parent: ID!) {
    removeAccountManagementMember(input: { id: $id, parent: $parent }) {
      done
      errors {
        field
        message
      }
    }
  }
`;

export const GET_RECORDS_TO_SELECT = gql`
  query GetRecordsToSelect(
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
  ) {
    getRecordsToSelect(input: { start: $start, end: $end, search: $search }) {
      recordsToSelect {
        value
        label
        level
        parent
        selectable
      }
    }
  }
`;

export const GET_TEAMS_AND_CUSTOMERS_TO_SELECT = gql`
  query getCustomersAndTeamsToSelect(
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
    $level: PositiveInt!
    $records: [ID]
    $parents: [ID]
  ) {
    getCustomersAndTeamsToSelect(
      input: {
        start: $start
        end: $end
        search: $search
        level: $level
        records: $records
        parents: $parents
      }
    ) {
      recordsToSelect {
        value
        label
        level
        parent
        teams {
          value
          label
          level
          parent
        }
      }
    }
  }
`;

export const GET_BUSINESS_CONFIG_RECORDS = gql`
  query GetBusinessConfigRecords(
    $type: BusinessConfigurationType!
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
    $parent: ID
  ) {
    getBusinessConfigRecords(
      input: {
        type: $type
        start: $start
        end: $end
        search: $search
        parent: $parent
      }
    ) {
      totalCount
      records {
        id
        name
        type
        parent
      }
      errors {
        field
        message
      }
    }
  }
`;

export const GET_BUSINESS_CONFIG_RECORDS_WITH_PARENTS = gql`
  query GetBusinessConfigRecordsWithParents(
    $parentType: BusinessConfigurationType!
    $type: BusinessConfigurationType!
    $start: PositiveInt!
    $end: PositiveInt!
    $search: String
  ) {
    getBusinessConfigRecordsWithParents(
      input: {
        parentType: $parentType
        type: $type
        start: $start
        end: $end
        search: $search
      }
    ) {
      totalCount
      records {
        id
        name
        type
        parent
      }
      errors {
        field
        message
      }
    }
  }
`;
