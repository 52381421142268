import React, { useMemo, useState, useEffect } from 'react';

import Popup from '../../../Popup';
import MergedGraph from './components/MergedGraph/MergedGraph';
import OpportunityGraph from './components/OpportunityGraph/OpportunityGraph';
import CustomGraph from './components/CustomGraph/CustomGraph';
import ConceptTestGraph from './components/ConceptTestGraph/ConceptTestGraph';
import ValueSelectionGraph from './components/ValueSelectionGraph/ValueSelectionGraph';
import IdeaSelectionGraph from './components/IdeaSelectionGraph/IdeaSelectionGraph';
import ContactInnovationExpertPopup from './components/ContactInnovationExpertPopup/ContactInnovationExpertPopup';
import { insightQuestionTypes } from '../../Insights/helpers/constants';
import { CHART_VIEW_NAMES } from '../../../../helpers/constants';
import { graphTypes } from './helpers/constants';

export default ({
  surveyId,
  surveyLanguage,
  questions,
  activeFilters,
  onToggleFilter,

  setEditGraphsPopup,
  surveyRefetch,

  isAllowedToExecuteRequests,

  content,
  orderQuestionsWithGroupNodes,

  viewToken,
  activeInsightId,
  insightsData,
  onToggleActiveBooleanState,
  activeFilteringOnSelection,
  activePresentationMode,
  activeChartView,
  setActiveChartView,

  setShowAddInsightsPopup,
  getViewAllGraphStats,
  setActiveInsightId,
  graph,
  index,

  surveyHiddenGraphs,
  surveyMergedGraphs
}) => {
  const [
    displayContactInnovationExpertPopup,
    setDisplayContactInnovationExpertPopup
  ] = useState(false);
  const [triggerUpdates, setTriggerUpdates] = useState({}); // State to trigger graph state changes for insights

  const activeInsight =
    insightsData &&
    insightsData.insights &&
    insightsData.insights.find(i => i._id.toString() === activeInsightId);

  useEffect(
    () => {
      const graphsToShow =
        activeInsight &&
        activeInsight.questions &&
        activeInsight.questions.length
          ? activeInsight.questions
              .filter(q => q.type === insightQuestionTypes.TEMPLATE)
              .map(q => q.questionId)
          : null;

      if (graphsToShow && graphsToShow.length) {
        setTriggerUpdates(prevTriggers => ({
          ...prevTriggers,
          ...graphsToShow.reduce(
            (idsObject, graphId) => ({
              ...idsObject,
              [graphId]: {
                deriveFrom: 'graph',
                triggerCount:
                  ((prevTriggers[graphId] &&
                    prevTriggers[graphId].triggerCount) ||
                    0) + 1
              }
            }),
            {}
          )
        }));
      } else {
        setTriggerUpdates(prevTriggers =>
          Object.keys(prevTriggers).reduce((acc, key) => {
            acc[key] = {
              deriveFrom: 'prevState',
              triggerCount: ((acc[key] && acc[key].triggerCount) || 0) + 1
            };
            return acc;
          }, {})
        );
      }
    },
    [activeInsightId]
  );

  const parseQuestion = question => {
    let questionValue;
    try {
      questionValue = JSON.parse(question)
        .blocks.map(draftBlock => draftBlock.text)
        .join('\n');
    } catch (error) {
      questionValue = question;
    }
    return questionValue;
  };

  const distributorGroups = useMemo(() => {
    const distributorBranches = orderQuestionsWithGroupNodes
      .reduce((acc, curr) => {
        if (curr.type === 'distributor') {
          acc.push(curr.branches);
        }
        return acc;
      }, [])
      .flat();

    if (distributorBranches.length && content.groups && content.groups.length) {
      const groups = content.groups.reduce((newGroups, currentGroup) => {
        const matchingBranch = distributorBranches.find(
          branch => branch.nextFlow === currentGroup.id
        );
        if (matchingBranch) {
          newGroups.push({
            id: currentGroup.id,
            flows: currentGroup.flows,
            branchLabel: matchingBranch.label
          });
        }
        return newGroups;
      }, []);
      if (groups.length) {
        return groups;
      }
    }
    return [];
  }, []);

  const isFiltered = !!(activeFilters && activeFilters.length > 0);

  const defaultDataPointColors = [
    '#E3080F',
    '#8FC001',
    '#EDEDED',
    '#0662D1',
    '#8B633C',
    '#FAEE0D',
    '#99BA0E',
    '#374B0C',
    '#B5863A',
    '#6C4F21',
    '#000000',
    '#A3A3A3'
  ];

  const returnInsightsGraph = (graphId, g) => {
    const insightsGraph = {
      ...g,
      features:
        g && g.features && g.features.length
          ? g.features.map(f => ({ ...f }))
          : [],
      unFilteredFeatures:
        g && g.unFilteredFeatures && g.unFilteredFeatures.length
          ? g.unFilteredFeatures.map(f => ({ ...f }))
          : []
    };

    if (
      activeInsight &&
      activeInsight.questionSettings &&
      activeInsight.questionSettings.graphFeatures &&
      activeInsight.questionSettings.graphFeatures.length
    ) {
      const insightDataForGraph = activeInsight.questionSettings.graphFeatures.find(
        gF => gF.graphId === graphId
      );
      if (insightDataForGraph) {
        const {
          featuresVisibility,
          unFilteredFeaturesVisibility,
          hiddenQuestionIndexes,
          activeLoversHaters
        } = insightDataForGraph;

        // Update the active state of each feature
        featuresVisibility.forEach(featureUpdate => {
          const feature = insightsGraph.features.find(
            f => f.id === featureUpdate.featureId
          );

          if (feature) {
            feature.active = featureUpdate.active;
          }
        });

        if (
          unFilteredFeaturesVisibility &&
          unFilteredFeaturesVisibility.length
        ) {
          unFilteredFeaturesVisibility.forEach(featureUpdate => {
            const uFeature = insightsGraph.unFilteredFeatures.find(
              f => f.id === featureUpdate.featureId
            );

            if (uFeature) {
              uFeature.active = featureUpdate.active;
            }
          });
        }

        if (hiddenQuestionIndexes && hiddenQuestionIndexes.length) {
          insightsGraph.hiddenQuestionIndexes = hiddenQuestionIndexes;
        }

        if (activeLoversHaters) {
          insightsGraph.activeLoversHaters = activeLoversHaters;
        }
      }
    }
    return insightsGraph;
  };

  const page = [];

  if (displayContactInnovationExpertPopup) {
    page.push(
      <Popup
        component={
          <ContactInnovationExpertPopup
            onClose={() => setDisplayContactInnovationExpertPopup(false)}
            surveyId={surveyId}
            isAllowedToExecuteRequests={isAllowedToExecuteRequests}
            viewToken={viewToken}
          />
        }
        customStyles={{ padding: 'none' }}
        onClose={() => setDisplayContactInnovationExpertPopup(false)}
      />
    );
  }

  if (graph.graphType === graphTypes.MERGED_GRAPH) {
    let filteredQuestions = graph.questions;
    let isHidden;

    const graphEntry =
      surveyHiddenGraphs &&
      surveyHiddenGraphs.mergedGraphs.find(mg => mg.graph === graph.id);
    if (graphEntry) {
      filteredQuestions = graph.questions.filter(
        q => graphEntry.questions.indexOf(q.id) === -1
      );

      if (filteredQuestions.length === 0) {
        isHidden = true;
      }
    }

    let mergedGraph = graph;
    if (activeInsightId) {
      mergedGraph = returnInsightsGraph(graph.id, graph);
    }

    if (!isHidden) {
      page.push(
        <MergedGraph
          key={`merged-graph-${index.toString()}`}
          mergedGraph={{ ...mergedGraph, questions: filteredQuestions }}
          questions={questions}
          surveyId={surveyId}
          mergedGraphs={surveyMergedGraphs}
          activeFilters={activeFilters}
          isFiltered={isFiltered}
          surveyRefetch={surveyRefetch}
          parseQuestion={parseQuestion}
          onToggleFilter={onToggleFilter}
          isAllowedToExecuteRequests={isAllowedToExecuteRequests}
          viewToken={viewToken}
          setShowAddInsightsPopup={setShowAddInsightsPopup}
          chartView={
            activeChartView && activeChartView[graph.id]
              ? activeChartView[graph.id].chartViewName
              : CHART_VIEW_NAMES.DEFAULT
          }
          setChartView={chartViewState =>
            setActiveChartView({
              ...activeChartView,
              [graph.id]: { chartViewName: chartViewState }
            })
          }
          insightQuestionType={insightQuestionTypes.TEMPLATE}
          filteringOnSelection={
            activeFilteringOnSelection && activeFilteringOnSelection[graph.id]
              ? activeFilteringOnSelection[graph.id].active
              : true
          }
          setFilteringOnSelection={newValue =>
            onToggleActiveBooleanState(
              'activeFilteringOnSelection',
              graph.id,
              newValue
            )
          }
        />
      );
    }
  }

  if (graph.graphType === graphTypes.OPPORTUNITY_GRAPH) {
    let insightsGraph = graph;
    if (activeInsightId) {
      insightsGraph = returnInsightsGraph(graph.id, graph);
    }

    page.push(
      <OpportunityGraph
        key={`opportunity-graph-${index.toString()}`}
        graph={insightsGraph}
        questions={questions}
        distributorGroups={distributorGroups}
        surveyId={surveyId}
        opportunityGraphIndex={index}
        setEditGraphsPopup={setEditGraphsPopup}
        isFiltered={isFiltered}
        activeFilters={activeFilters}
        setDisplayContactInnovationExpertPopup={
          setDisplayContactInnovationExpertPopup
        }
        onToggleFilter={onToggleFilter}
        isAllowedToExecuteRequests={isAllowedToExecuteRequests}
        defaultDataPointColors={defaultDataPointColors}
        viewToken={viewToken}
        setShowAddInsightsPopup={setShowAddInsightsPopup}
        triggerUpdate={triggerUpdates[graph.id]}
        onToggleActiveBooleanState={onToggleActiveBooleanState}
        filteringOnSelection={
          activeFilteringOnSelection && activeFilteringOnSelection[graph.id]
            ? activeFilteringOnSelection[graph.id].active
            : true
        }
        setFilteringOnSelection={newValue =>
          onToggleActiveBooleanState(
            'activeFilteringOnSelection',
            graph.id,
            newValue
          )
        }
        presentationModeEnabled={
          activePresentationMode && activePresentationMode[graph.id]
            ? activePresentationMode[graph.id].active
            : false
        }
        setPresentationModeEnabled={newValue =>
          onToggleActiveBooleanState(
            'activePresentationMode',
            graph.id,
            newValue
          )
        }
        getViewAllGraphStats={getViewAllGraphStats}
        setActiveInsightId={setActiveInsightId}
      />
    );
  }

  if (graph.graphType === graphTypes.CUSTOM_GRAPH) {
    let insightsGraph = graph;
    if (activeInsightId) {
      insightsGraph = returnInsightsGraph(graph.id, graph);
    }

    page.push(
      <CustomGraph
        key={`custom-graph-${index.toString()}`}
        graph={insightsGraph}
        questions={questions}
        distributorGroups={distributorGroups}
        surveyId={surveyId}
        surveyLanguage={surveyLanguage}
        customGraphIndex={index}
        setEditGraphsPopup={setEditGraphsPopup}
        isFiltered={isFiltered}
        activeFilters={activeFilters}
        onToggleFilter={onToggleFilter}
        isAllowedToExecuteRequests={isAllowedToExecuteRequests}
        setDisplayContactInnovationExpertPopup={
          setDisplayContactInnovationExpertPopup
        }
        defaultDataPointColors={defaultDataPointColors}
        viewToken={viewToken}
        setShowAddInsightsPopup={setShowAddInsightsPopup}
        triggerUpdate={triggerUpdates[graph.id]}
        filteringOnSelection={
          activeFilteringOnSelection && activeFilteringOnSelection[graph.id]
            ? activeFilteringOnSelection[graph.id].active
            : true
        }
        setFilteringOnSelection={newValue =>
          onToggleActiveBooleanState(
            'activeFilteringOnSelection',
            graph.id,
            newValue
          )
        }
        presentationModeEnabled={
          activePresentationMode && activePresentationMode[graph.id]
            ? activePresentationMode[graph.id].active
            : false
        }
        setPresentationModeEnabled={newValue =>
          onToggleActiveBooleanState(
            'activePresentationMode',
            graph.id,
            newValue
          )
        }
        getViewAllGraphStats={getViewAllGraphStats}
        setActiveInsightId={setActiveInsightId}
      />
    );
  }

  if (graph.graphType === graphTypes.CONCEPT_TEST_GRAPH) {
    let insightsGraph = graph;
    if (activeInsightId) {
      insightsGraph = returnInsightsGraph(graph.id, graph);
    }

    page.push(
      <ConceptTestGraph
        key={`concept-test-graph-${index.toString()}`}
        graph={insightsGraph}
        questions={questions}
        distributorGroups={distributorGroups}
        surveyId={surveyId}
        opportunityGraphIndex={index}
        setEditGraphsPopup={setEditGraphsPopup}
        isFiltered={isFiltered}
        activeFilters={activeFilters}
        setDisplayContactInnovationExpertPopup={
          setDisplayContactInnovationExpertPopup
        }
        onToggleFilter={onToggleFilter}
        isAllowedToExecuteRequests={isAllowedToExecuteRequests}
        defaultDataPointColors={defaultDataPointColors}
        viewToken={viewToken}
        setShowAddInsightsPopup={setShowAddInsightsPopup}
        triggerUpdate={triggerUpdates[graph.id]}
        filteringOnSelection={
          activeFilteringOnSelection && activeFilteringOnSelection[graph.id]
            ? activeFilteringOnSelection[graph.id].active
            : true
        }
        setFilteringOnSelection={newValue =>
          onToggleActiveBooleanState(
            'activeFilteringOnSelection',
            graph.id,
            newValue
          )
        }
        presentationModeEnabled={
          activePresentationMode && activePresentationMode[graph.id]
            ? activePresentationMode[graph.id].active
            : false
        }
        setPresentationModeEnabled={newValue =>
          onToggleActiveBooleanState(
            'activePresentationMode',
            graph.id,
            newValue
          )
        }
        getViewAllGraphStats={getViewAllGraphStats}
        setActiveInsightId={setActiveInsightId}
      />
    );
  }

  if (graph.graphType === graphTypes.VALUE_SELECTION_GRAPH) {
    let insightsGraph = graph;
    if (activeInsightId) {
      insightsGraph = returnInsightsGraph(graph.id, graph);
    }

    page.push(
      <ValueSelectionGraph
        key={`value-selection-graph-${index.toString()}`}
        graph={insightsGraph}
        questions={questions}
        distributorGroups={distributorGroups}
        surveyId={surveyId}
        customGraphIndex={index}
        setEditGraphsPopup={setEditGraphsPopup}
        isFiltered={isFiltered}
        activeFilters={activeFilters}
        setDisplayContactInnovationExpertPopup={
          setDisplayContactInnovationExpertPopup
        }
        onToggleFilter={onToggleFilter}
        isAllowedToExecuteRequests={isAllowedToExecuteRequests}
        defaultDataPointColors={defaultDataPointColors}
        viewToken={viewToken}
        setShowAddInsightsPopup={setShowAddInsightsPopup}
        triggerUpdate={triggerUpdates[graph.id]}
        filteringOnSelection={
          activeFilteringOnSelection && activeFilteringOnSelection[graph.id]
            ? activeFilteringOnSelection[graph.id].active
            : true
        }
        setFilteringOnSelection={newValue =>
          onToggleActiveBooleanState(
            'activeFilteringOnSelection',
            graph.id,
            newValue
          )
        }
        presentationModeEnabled={
          activePresentationMode && activePresentationMode[graph.id]
            ? activePresentationMode[graph.id].active
            : false
        }
        setPresentationModeEnabled={newValue =>
          onToggleActiveBooleanState(
            'activePresentationMode',
            graph.id,
            newValue
          )
        }
        getViewAllGraphStats={getViewAllGraphStats}
        setActiveInsightId={setActiveInsightId}
      />
    );
  }

  if (graph.graphType === graphTypes.IDEA_SELECTION_GRAPH) {
    let insightsGraph = graph;
    if (activeInsightId) {
      insightsGraph = returnInsightsGraph(graph.id, graph);
    }

    page.push(
      <IdeaSelectionGraph
        key={`idea-selection-graph-${index.toString()}`}
        graph={insightsGraph}
        questions={questions}
        distributorGroups={distributorGroups}
        surveyId={surveyId}
        customGraphIndex={index}
        setEditGraphsPopup={setEditGraphsPopup}
        isFiltered={isFiltered}
        activeFilters={activeFilters}
        setDisplayContactInnovationExpertPopup={
          setDisplayContactInnovationExpertPopup
        }
        onToggleFilter={onToggleFilter}
        isAllowedToExecuteRequests={isAllowedToExecuteRequests}
        defaultDataPointColors={defaultDataPointColors}
        viewToken={viewToken}
        setShowAddInsightsPopup={setShowAddInsightsPopup}
        triggerUpdate={triggerUpdates[graph.id]}
        filteringOnSelection={
          activeFilteringOnSelection && activeFilteringOnSelection[graph.id]
            ? activeFilteringOnSelection[graph.id].active
            : true
        }
        setFilteringOnSelection={newValue =>
          onToggleActiveBooleanState(
            'activeFilteringOnSelection',
            graph.id,
            newValue
          )
        }
        presentationModeEnabled={
          activePresentationMode && activePresentationMode[graph.id]
            ? activePresentationMode[graph.id].active
            : false
        }
        setPresentationModeEnabled={newValue =>
          onToggleActiveBooleanState(
            'activePresentationMode',
            graph.id,
            newValue
          )
        }
        getViewAllGraphStats={getViewAllGraphStats}
        setActiveInsightId={setActiveInsightId}
      />
    );
  }

  // eslint-disable-next-line consistent-return
  return page;
};
