import React, { useState, useEffect } from 'react';

import Icon from '../../Icon';
import blackArrowDown from './assets/black-arrow-down.svg';
import DebounceInput from '../../DebounceInput/DebounceInput';
import FilterDropdownSelector from './FilterDropdownSelector/FilterDropdownSelector';
import ActiveFilters from './ActiveFilters/ActiveFilters';
import DateCampaignFilter from './DateCampaignFilter/DateCampaignFilter';
import Loader from '../../Loader/Loader';
import styles from './CampaignFilters.module.css';
import filterResultsButton from './assets/filter-results-button.svg';

import useLazyQuery from '../../../../surveys/hooks/useLazyQuery';

import { GET_TEAMS_AND_CUSTOMERS_TO_SELECT } from '../../../../graphql/AccountManagement';

const CampaignFilters = props => {
  const {
    totalCampaigns,
    availableClients,
    statuses,
    filter,
    setCampaignFilters,
    isProcessing,
    isFetching,
    isAdmin
  } = props;
  const [showFiltersContainer, setShowFiltersContainer] = useState(false);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);

  const campaignStatuses = Object.keys(statuses).map(statusValue => ({
    value: statusValue,
    label: statuses[statusValue]
  }));

  const webMobileOptions = [
    {
      value: 'WEB_VERSION_ONLY',
      label: 'Web version only'
    },
    {
      value: 'WEB_AND_MOBILE_VERSIONS',
      label: 'Web and mobile versions'
    }
  ];

  const filterOptions = {
    language: {
      dutch: {
        label: 'Dutch',
        value: 'Dutch'
      },
      french: {
        label: 'French',
        value: 'French'
      },
      english: {
        label: 'English',
        value: 'English'
      },
      german: {
        label: 'German',
        value: 'German'
      },
      noLanguage: {
        label: 'No language',
        value: 'noLang'
      }
    },
    date: {
      published: {
        label: 'Published',
        value: filter && filter.dates && filter.dates.published
      },
      lastResponse: {
        label: 'Last response',
        value: filter && filter.dates && filter.dates.lastResponse
      }
    },
    community: {
      target: {
        label: 'Sample size',
        description: 'e.g. 200, 0-50',
        value:
          filter &&
          filter.community &&
          filter.community.target &&
          filter.community.target.value,
        onChange: value =>
          setCampaignFilters({
            ...filter,
            community: {
              ...filter.community,
              target: (value || value === '') && {
                value,
                label: value
              }
            }
          })
      },
      responses: {
        label: 'Responses',
        description: 'e.g. 200, 0-50',
        value:
          filter &&
          filter.community &&
          filter.community.responses &&
          filter.community.responses.value,
        onChange: value =>
          setCampaignFilters({
            ...filter,
            community: {
              ...filter.community,
              responses: (value || value === '') && {
                value,
                label: value
              }
            }
          })
      }
    },
    type: {
      webMobile: {
        label: 'Web / mobile',
        placeholder: 'Choose a survey type',
        value: filter && filter.webMobile && filter.webMobile.value,
        onChange: e =>
          setCampaignFilters({
            ...filter,
            webMobile: e.target.value && {
              value: e.target.value,
              label: webMobileOptions.filter(
                option => option.value === e.target.value
              )[0].label
            }
          }),
        options: webMobileOptions
      },
      status: {
        label: 'Status',
        placeholder: 'Choose a status',
        value: filter && filter.status && filter.status.value,
        onChange: e =>
          setCampaignFilters({
            ...filter,
            status: e.target.value && {
              value: e.target.value,
              label: statuses[e.target.value]
            }
          }),
        options: campaignStatuses
      }
    },
    account: {
      customers: {
        value: 'customers',
        label: 'Customer',
        filter: 'records',
        visible: isAdmin
      },
      teams: {
        value: 'teams',
        label: 'Business challenge',
        filter: 'records',
        visible: isAdmin
      },
      clients: {
        value: 'clients',
        label: 'Survey creator',
        filter: 'clients',
        visible: true
      }
    }
  };

  const searchAndUpdateCustomers = (customerIds, customersToBeSelected) => {
    const runFetchOptions = async () => {
      const stillToBeSelectedCustomers =
        customerIds && customerIds.length
          ? await searchRecords(0, '', 1, customerIds)
          : [];
      setSelectedCustomers([
        ...customersToBeSelected,
        ...stillToBeSelectedCustomers
      ]);
    };
    runFetchOptions();
  };

  useEffect(() => {
    if (
      filter &&
      filter.records &&
      filter.records.length &&
      !(
        (selectedCustomers && selectedCustomers.length) ||
        (selectedTeams && selectedTeams.length)
      )
    ) {
      const { customers, teams, teamsCustomersMapping } = filter.records.reduce(
        (acc, curr) => {
          if (curr.level === 2 && curr.parent) {
            acc.teams = [...acc.teams, curr];
            if (acc.teamsCustomersMapping[curr.parent]) {
              acc.teamsCustomersMapping[curr.parent] = [
                ...acc.teamsCustomersMapping[curr.parent],
                curr
              ];
            } else {
              acc.teamsCustomersMapping[curr.parent] = [curr];
            }
          } else {
            acc.customers = [...acc.customers, curr];
          }
          return acc;
        },
        { customers: [], teams: [], teamsCustomersMapping: {} }
      );

      let teamsToBeSelected = [];

      Object.entries(teamsCustomersMapping).forEach(
        ([customerId, teamsFromCustomer]) => {
          if (
            customers &&
            customers.length &&
            (customers.some(
              customer =>
                customer.value === customerId &&
                customer.teams &&
                customer.teams.length !== teamsFromCustomer.length
            ) ||
              !customers.some(customer => customer.value === customerId))
          ) {
            teamsToBeSelected = [...teamsToBeSelected, ...teamsFromCustomer];
          } else if (!(customers && customers.length)) {
            teamsToBeSelected = [...teamsToBeSelected, ...teamsFromCustomer];
          }
        }
      );

      if (
        teams.some(
          team => !customers.some(customer => customer.value === team.parent)
        )
      ) {
        const stillToBeSelectedCustomerIds = teams.reduce(
          (customerIds, currenTeam) => {
            if (
              currenTeam.parent &&
              !customerIds.includes(currenTeam.parent) &&
              !customers.some(
                customerToBeSelected =>
                  customerToBeSelected.value === currenTeam.parent
              )
            ) {
              return [...customerIds, currenTeam.parent];
            }
            return customerIds;
          },
          []
        );

        searchAndUpdateCustomers(stillToBeSelectedCustomerIds, customers);
      } else {
        setSelectedCustomers(customers);
      }
      setSelectedTeams(teamsToBeSelected);
    }
  }, []);

  const searchAccountManagementTeamsAndCustomersApi = useLazyQuery(
    GET_TEAMS_AND_CUSTOMERS_TO_SELECT,
    {
      fetchPolicy: 'no-cache'
    }
  );

  const searchRecords = async (p, search, level, records) => {
    const searchResults = await searchAccountManagementTeamsAndCustomersApi({
      variables: {
        start: 0,
        end: (p + 1) * 10,
        search,
        level,
        ...(records && records.length ? { records } : {}),
        ...(level === 2 && selectedCustomers && selectedCustomers.length
          ? {
              parents: selectedCustomers.map(
                selectedCustomer => selectedCustomer.value
              )
            }
          : {})
      }
    });

    if (
      searchResults &&
      searchResults.data &&
      searchResults.data.getCustomersAndTeamsToSelect &&
      searchResults.data.getCustomersAndTeamsToSelect.recordsToSelect &&
      searchResults.data.getCustomersAndTeamsToSelect.recordsToSelect.length
    ) {
      return searchResults.data.getCustomersAndTeamsToSelect.recordsToSelect;
    }

    return [];
  };

  const getTeamsOfCustomer = (customers, initialValues) =>
    customers.reduce((acc, curr) => {
      if (curr.teams && curr.teams.length) {
        return [
          ...acc,
          ...curr.teams.filter(
            teamRecord => !acc.some(record => record.value === teamRecord.value)
          )
        ];
      }
      return acc;
    }, initialValues || []);

  const onAccountsSelectCompleted = async (accountType, accounts) => {
    let filterRecords = [];

    if (accountType.value === filterOptions.account.customers.value) {
      /* Customer filtering */
      let customersToFilterUpon = [];
      if (selectedTeams && selectedTeams.length) {
        // Team filtering is more specific - no need to filter on records of customer and it's teams if there is a more specific team filter
        customersToFilterUpon = accounts.filter(
          customer =>
            !selectedTeams.some(
              team => team.parent && team.parent === customer.value
            )
        );
      } else {
        customersToFilterUpon = [...accounts];
      }

      // Get customers their team records
      const customerTeams = getTeamsOfCustomer(
        customersToFilterUpon,
        selectedTeams
      );

      filterRecords = [...customersToFilterUpon, ...customerTeams];
      setSelectedCustomers(accounts);
    } else if (accountType.value === filterOptions.account.teams.value) {
      /* Team filtering */
      let customersToFilterUpon = [];
      if (
        selectedCustomers &&
        selectedCustomers.length &&
        selectedCustomers.some(
          selectedCustomer =>
            !accounts.some(
              team => team.parent && team.parent === selectedCustomer.value
            )
        )
      ) {
        const newSelectedCustomers = [...selectedCustomers].map(c => ({
          ...c,
          ...(c.teams && c.teams.length
            ? { teams: c.teams.map(t => ({ ...t })) }
            : {})
        }));
        // Is there a customer that has no related team account
        const customersWithNoSelectedTeams = newSelectedCustomers.filter(
          customer =>
            !accounts.some(
              team => team.parent && team.parent === customer.value
            )
        );
        if (customersWithNoSelectedTeams.length) {
          const customerTeams = getTeamsOfCustomer(
            customersWithNoSelectedTeams
          );
          customersToFilterUpon = [
            ...customersWithNoSelectedTeams,
            ...customerTeams
          ];
        }
      }

      // Filter on teams + customers that have no specific selected teams
      filterRecords = [...accounts, ...customersToFilterUpon];
      setSelectedTeams(accounts);

      // Update customers dropdown if there is a team of which the parent (customer) is not yet in selectedCustomers
      if (
        accounts.length &&
        (!(selectedCustomers && selectedCustomers.length) ||
          (selectedCustomers &&
            selectedCustomers.length &&
            !selectedCustomers.some(customer =>
              accounts.some(
                team => team.parent && team.parent === customer.value
              )
            )))
      ) {
        const stillToBeSelectedCustomerIds = accounts.reduce(
          (customerIds, currenTeam) => {
            if (
              currenTeam.parent &&
              !customerIds.includes(currenTeam.parent) &&
              !selectedCustomers.some(
                selectedCustomer => selectedCustomer.value === currenTeam.parent
              )
            ) {
              return [...customerIds, currenTeam.parent];
            }
            return customerIds;
          },
          []
        );

        searchAndUpdateCustomers(
          stillToBeSelectedCustomerIds,
          selectedCustomers
        );
      }
    } else {
      /* Client filtering */
      filterRecords = [...accounts];
    }
    setCampaignFilters({ ...filter, [accountType.filter]: filterRecords });
  };

  const isLanguageSelected = language => {
    const selectedLanguage =
      filter &&
      filter.language &&
      filter.language.filter(option => option.value === language);

    if (selectedLanguage && selectedLanguage.length) {
      return true;
    }
    return false;
  };

  const getAlreadySelectedOptions = accountType => {
    if (
      accountType.value === filterOptions.account.clients.value &&
      filter.clients &&
      filter.clients.length
    ) {
      return filter.clients;
    }
    if (accountType.value === filterOptions.account.customers.value) {
      return selectedCustomers;
    }
    if (accountType.value === filterOptions.account.teams.value) {
      return selectedTeams;
    }
    return [];
  };

  const getAvailableClients = () => {
    let clientsToReturn = [...availableClients];
    if (
      (selectedCustomers && selectedCustomers.length) ||
      (selectedTeams && selectedTeams.length)
    ) {
      clientsToReturn = clientsToReturn.reduce((visibleClients, client) => {
        if (
          client.accountManagementParents &&
          client.accountManagementParents.length
        ) {
          if (selectedTeams && selectedTeams.length) {
            if (
              client.accountManagementParents.some(account =>
                selectedTeams.some(team => team.value === account.parent)
              )
            ) {
              // Client is part of team
              return [...visibleClients, client];
            }
            if (
              selectedCustomers &&
              selectedCustomers.length &&
              selectedCustomers.some(
                customer =>
                  !(customer.teams && customer.teams.length) ||
                  !customer.teams.some(cTeam =>
                    selectedTeams.some(team => team.value === cTeam.value)
                  )
              ) &&
              client.accountManagementParents.some(account =>
                selectedCustomers.some(
                  customer =>
                    customer.value === account.parent ||
                    (customer.teams &&
                      customer.teams.length &&
                      customer.teams.some(
                        cTeam => cTeam.value === account.parent
                      ))
                )
              )
            ) {
              // Client is part of customer of which there is no specific team filtering
              return [...visibleClients, client];
            }
          } else if (
            client.accountManagementParents.some(account =>
              selectedCustomers.some(
                customer =>
                  customer.value === account.parent ||
                  (customer.teams &&
                    customer.teams.length &&
                    customer.teams.some(
                      cTeam => cTeam.value === account.parent
                    ))
              )
            )
          ) {
            // Client is part of customer or it's teams
            return [...visibleClients, client];
          }
        }
        return visibleClients;
      }, []);
    }
    return clientsToReturn.map(availableClient => ({
      value: availableClient.id,
      label: availableClient.email
    }));
  };

  if (!totalCampaigns && totalCampaigns !== 0) return null;

  return (
    <div className={styles.campaignsFilterContainer}>
      <div className={styles.header}>
        <div
          role="presentation"
          className={
            showFiltersContainer
              ? `${styles.filtersButton} ${styles.filtersButtonActive}`
              : styles.filtersButton
          }
          onClick={() => setShowFiltersContainer(!showFiltersContainer)}
        >
          <img src={filterResultsButton} alt="Filter result icon" />
          Filters
        </div>
        <div className={styles.campaignsCount}>
          {isProcessing || isFetching ? <Loader /> : totalCampaigns}
          <span className={styles.campaignsLabel}>surveys</span>
        </div>
      </div>
      {showFiltersContainer ? (
        <div className={styles.filtersContainer}>
          {isProcessing || isFetching ? (
            <div className={styles.filtersContainerOverlay} />
          ) : null}
          <div className={styles.filtersContainerNarrowSection}>
            <div className={styles.sectionTitle}>Language</div>
            {Object.keys(filterOptions.language).map((language, index) => (
              <div
                className={styles.languageItem}
                key={`language-container-${index.toString()}`}
                role="presentation"
                onClick={() => {
                  const { value, label } = filterOptions.language[language];

                  let filterLanguage = filter.language ? filter.language : [];
                  const selectedLanguage = filterLanguage.filter(
                    lang => lang.value === value
                  );

                  if (selectedLanguage && selectedLanguage.length) {
                    filterLanguage = filterLanguage.filter(
                      singleLanguage => singleLanguage.value !== value
                    );
                  } else {
                    filterLanguage = [...filterLanguage, { value, label }];
                  }
                  setCampaignFilters({ ...filter, language: filterLanguage });
                }}
              >
                <span
                  className={
                    isLanguageSelected(filterOptions.language[language].value)
                      ? styles.subsectionItemCheckboxSelected
                      : styles.subsectionItemCheckbox
                  }
                >
                  <Icon
                    type={
                      isLanguageSelected(filterOptions.language[language].value)
                        ? 'checked'
                        : 'unchecked'
                    }
                  />
                </span>
                <span
                  className={`${styles.subsectionItemLabel} ${
                    styles.subsectionItemLabelLanguage
                  }`}
                >
                  {filterOptions.language[language].label}
                </span>
              </div>
            ))}
          </div>
          <div className={styles.filtersContainerWideSection}>
            {Object.values(filterOptions.account)
              .filter(accountType => accountType.visible)
              .map((accountType, index) => (
                <div
                  className={styles.clientContainer}
                  key={`client-container-${index.toString()}`}
                >
                  <div className={styles.sectionTitle}>{accountType.label}</div>
                  <FilterDropdownSelector
                    options={
                      accountType.value === filterOptions.account.clients.value
                        ? getAvailableClients()
                        : []
                    }
                    alreadySelectedOptions={getAlreadySelectedOptions(
                      accountType
                    )}
                    onSelectCompleted={onAccountsSelectCompleted}
                    accountType={accountType}
                    accountTypes={filterOptions.account}
                    searchRecords={searchRecords}
                    level={
                      accountType.value ===
                      filterOptions.account.customers.value
                        ? 1
                        : 2
                    }
                  />
                </div>
              ))}
          </div>
          <div className={styles.filtersContainerNormalSection}>
            {Object.keys(filterOptions.date).map((date, index) => (
              <DateCampaignFilter
                key={`date-campaign-filter-${index.toString()}`}
                filter={filter}
                filterOptions={filterOptions}
                date={date}
                index={index}
                setCampaignFilters={setCampaignFilters}
              />
            ))}
            <div className={styles.sectionBottomDescription}>
              Note: after and before date is included
            </div>
          </div>
          <div className={styles.filtersContainerNormalSection}>
            {Object.keys(filterOptions.community).map((community, index) => (
              <div
                className={styles.communityContainer}
                key={`community-container-${index.toString()}`}
              >
                <div className={styles.sectionTitle}>
                  {filterOptions.community[community].label}
                </div>
                <DebounceInput
                  className={styles.filterInput}
                  onKeyDown={evt => {
                    const validkeys = [
                      '1',
                      '2',
                      '3',
                      '4',
                      '5',
                      '6',
                      '7',
                      '8',
                      '9',
                      '0',
                      '-',
                      'Backspace'
                    ];
                    if (validkeys.indexOf(evt.key) < 0) {
                      evt.preventDefault();
                      return false;
                    }
                    return true;
                  }}
                  initialValue={filterOptions.community[community].value}
                  onChangeValue={filterOptions.community[community].onChange}
                />
                <div className={styles.bottomDescription}>
                  {filterOptions.community[community].description}
                </div>
              </div>
            ))}
          </div>
          <div className={styles.filtersContainerNormalSection}>
            {Object.keys(filterOptions.type).map((type, index) => (
              <div
                className={styles.typeContainer}
                key={`type-container-${index.toString()}`}
              >
                <div className={styles.sectionTitle}>
                  {filterOptions.type[type].label}
                </div>
                <select
                  className={styles.filterDropdown}
                  style={{ backgroundImage: `url(${blackArrowDown})` }}
                  value={
                    filterOptions &&
                    filterOptions.type &&
                    filterOptions.type[type] &&
                    filterOptions.type[type].value
                      ? filterOptions.type[type].value
                      : ''
                  }
                  onChange={filterOptions.type[type].onChange}
                >
                  <option value="">
                    {filterOptions.type[type].placeholder}
                  </option>
                  {filterOptions.type[type].options.map(
                    (option, optionIndex) => (
                      <option
                        value={option.value}
                        key={`filter-type-select-option-${optionIndex.toString()}`}
                      >
                        {option.label}
                      </option>
                    )
                  )}
                </select>
              </div>
            ))}
          </div>
        </div>
      ) : null}
      <ActiveFilters
        filter={filter}
        setCampaignFilters={setCampaignFilters}
        selectedTeams={selectedTeams}
        setSelectedTeams={setSelectedTeams}
        selectedCustomers={selectedCustomers}
        setSelectedCustomers={setSelectedCustomers}
        onAccountsSelectCompleted={onAccountsSelectCompleted}
        accountTypes={filterOptions.account}
      />
    </div>
  );
};

export default CampaignFilters;
