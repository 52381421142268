import React, { useState, useEffect } from 'react';
import DebounceInput from '../../../../../campaigns/components/DebounceInput/DebounceInput';
import Loader from '../../../../../campaigns/components/Loader/Loader';

import styles from './DropdownSelector.module.css';
import whiteArrowDown from './assets/black-arrow-down.svg';

export default ({
  options,
  fetchOptions,
  value,
  onSelectCompleted,
  multiple,
  placeholder,
  search,
  filterDropdownStyle,
  disableDropdownExpand,
  hideNoValueOption,
  fixedLeftPadding,
  surveyAssignDropdown
}) => {
  let wrapperRef = null;

  const [dropdownWrapperRef, setDropdownWrapperRef] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [page, setPage] = useState(0);
  const [topPosition, setTopPosition] = useState(-1000); // hacky but works
  const [leftPosition, setLeftPosition] = useState(-1000); // hacky but works
  const [isFetching, setIsFetching] = useState(false);
  const [availableOptions, setAvailableOptions] = useState(options || []);
  const [isMaxReached, setIsMaxReached] = useState(false);

  const handleClickOutside = event => {
    if (wrapperRef && !wrapperRef.contains(event.target)) {
      setShowPopup(false);
    }
  };

  const fetchOptionsExecute = (targetPage, inputFieldSearch, forceSearch) => {
    const runFetchOptions = async () => {
      const searchString =
        inputFieldSearch || inputFieldSearch === ''
          ? inputFieldSearch
          : inputSearch;
      setPage(targetPage);
      const fetchedOptions = await fetchOptions(targetPage, searchString);

      if (
        availableOptions &&
        fetchedOptions &&
        availableOptions.length === fetchedOptions.length
      ) {
        setIsMaxReached(true);
      }

      setAvailableOptions(fetchedOptions);
      setIsFetching(false);
    };

    if (
      fetchOptions &&
      !isFetching &&
      (!isMaxReached || forceSearch === true)
    ) {
      setIsFetching(true);
      runFetchOptions();
    }
  };

  const onScroll = e => {
    const isDropdownPopup =
      e &&
      e.target &&
      e.target.className &&
      // ((typeof str === 'string') || (e.target.className instanceof String)) &&
      e.target.className.toString().substring(0, 35) &&
      e.target.className.toString().substring(0, 35) ===
        'DropdownSelector_filterDropdownItem'; // hacky, but I do not see a better way to solve it now

    if (!isDropdownPopup && showPopup) {
      setShowPopup(false);
    }
  };

  const emptyState = () => {
    setPage(0);
    setInputSearch('');
    setAvailableOptions([]);
    setIsMaxReached(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(
    () => () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('wheel', onScroll, {
        passive: false
      });
      setAvailableOptions([]);
    },
    []
  );

  useEffect(() => setAvailableOptions(options), [options]);

  useEffect(
    () => {
      if (showPopup) {
        document.addEventListener('wheel', onScroll, {
          passive: false
        });
        fetchOptionsExecute(0);
      } else {
        document.removeEventListener('wheel', onScroll, {
          passive: false
        });
        emptyState();
      }
    },
    [showPopup]
  );

  const selectValue = option => {
    let processedRecord = null;

    if (
      option &&
      (option.selectable ||
        option.value === 'All' ||
        option.value === 'No account')
    ) {
      if (option && option.value === 'All') {
        processedRecord = {
          value: 'All',
          label: 'All'
        };
      } else if (option.value === 'No account') {
        onSelectCompleted({
          label: 'No account',
          value: 'No account'
        });
        setShowPopup(false);
      } else {
        const selectedRecord = availableOptions.find(
          aO => aO.value === option.value
        );

        if (selectedRecord) {
          processedRecord = {
            ...selectedRecord,
            id: selectedRecord.value,
            name: selectedRecord.label
          };
        }
      }

      if (processedRecord) {
        onSelectCompleted(processedRecord);
        if (!multiple) {
          setShowPopup(false);
        }
      }
    }
  };
  const listOptionsDivScroll = async event => {
    const elem = event.currentTarget;
    if (elem.scrollHeight - elem.scrollTop - 5 <= elem.offsetHeight) {
      fetchOptionsExecute(page + 1, inputSearch);
    }
  };
  const onInputSearchChangeValue = searchValue => {
    setIsMaxReached(false);
    setInputSearch(searchValue);
    fetchOptionsExecute(0, searchValue, true);
  };

  useEffect(
    () => {
      if (dropdownWrapperRef) {
        const height = dropdownWrapperRef.offsetHeight;
        const rect = dropdownWrapperRef.getBoundingClientRect();
        const win = dropdownWrapperRef.ownerDocument.defaultView;

        setTopPosition(rect.top + win.pageYOffset - window.scrollY + height);
        setLeftPosition(
          rect.left + win.pageXOffset - window.scrollX - (fixedLeftPadding || 0)
        );
      }
    },
    [showPopup]
  );

  const getOptionStyle = (option, index) => {
    let endStyle = styles.filterDropdownItem;

    if (value && value.value === option.value) {
      endStyle = `${endStyle} ${styles.active}`;
    }

    if (option.level === 1 && index > 0) {
      endStyle = `${endStyle} ${styles.selfRecord}`;
    }

    if (!option.selectable) {
      endStyle = `${endStyle} ${styles.notSelectable}`;
    }

    return endStyle;
  };

  return (
    <div
      className={filterDropdownStyle || styles.filterDropdownSelectorContainer}
      ref={elem => {
        if (elem) {
          wrapperRef = elem;
          setDropdownWrapperRef(elem);
        }
      }}
    >
      <div
        className={`${styles.dropdownPicker} ${
          disableDropdownExpand ? styles.disabled : ''
        }`}
        onClick={() => {
          if (!disableDropdownExpand) {
            setShowPopup(!showPopup);
          }
        }}
        role="presentation"
      >
        <div className={styles.dropdownPickerLabel}>
          {value && value.label ? value.label : placeholder}
        </div>
        <img
          src={whiteArrowDown}
          className={styles.arrowDown}
          alt="Arrow down"
          height="5"
        />
      </div>
      {showPopup ? (
        <div
          className={styles.filterDropdownPopup}
          style={{ left: `${leftPosition}px`, top: `${topPosition}px` }}
        >
          {search ? (
            <div className={styles.filterDropdownPopupSearchInputContainer}>
              <DebounceInput
                initialValue={inputSearch}
                onChangeValue={searchValue =>
                  onInputSearchChangeValue(searchValue)
                }
              />
              <div className={styles.inputLoaderContainer}>
                {isFetching && availableOptions.length > 0 ? (
                  <Loader size="small" />
                ) : null}
              </div>
            </div>
          ) : null}
          <div
            className={styles.filterDropdownPopupItemsContainer}
            onScroll={listOptionsDivScroll}
          >
            {!hideNoValueOption ? (
              <div
                className={`${styles.filterDropdownItem} ${styles.allOption}`}
                role="presentation"
                onClick={() => selectValue({ label: 'All', value: 'All' })}
              >
                <div className={styles.filterDropdownItemLabel}>All</div>
              </div>
            ) : null}
            {surveyAssignDropdown ? (
              <div
                className={`${styles.filterDropdownItem} ${styles.allOption}`}
                role="presentation"
                onClick={() =>
                  selectValue({ label: 'No account', value: 'No account' })
                }
              >
                <div className={styles.filterDropdownItemLabel}>No account</div>
              </div>
            ) : null}
            {availableOptions && availableOptions.length
              ? availableOptions.map((option, index) => (
                  <div
                    className={getOptionStyle(option, index)}
                    key={`filter-dropdown-item-${index.toString()}`}
                    role="presentation"
                    onClick={() => selectValue(option)}
                  >
                    <div className={styles.filterDropdownItemLabel}>
                      {option.level === 2 ? (
                        <span className={styles.level2Padding} />
                      ) : null}
                      {option.label}
                    </div>
                  </div>
                ))
              : null}
            {!isFetching &&
            (!availableOptions ||
              (availableOptions && availableOptions.length === 0)) ? (
              <div className={styles.noAvailableClientAccounts}>
                No available options
              </div>
            ) : null}
            {isFetching && availableOptions.length === 0 ? (
              <div className={styles.loaderContainer}>
                <Loader size="small" />
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
};
