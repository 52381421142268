import React, { useState, useEffect } from 'react';
import Icon from '../../../Icon';

import styles from './ActiveFilters.module.css';

const ActiveFilters = props => {
  const {
    filter,
    setCampaignFilters,
    selectedTeams,
    setSelectedTeams,
    selectedCustomers,
    setSelectedCustomers,
    onAccountsSelectCompleted,
    accountTypes
  } = props;

  const [filtersList, setFiltersList] = useState([]);

  const filterGroupLabel = {
    clients: 'Survey creator',
    responses: 'Responses',
    target: 'Sample size',
    language: 'Language',
    status: 'Status',
    webMobile: 'Web / Mobile',
    published: 'Published Date is',
    lastResponse: 'Last Response is',
    after: 'after',
    before: 'before',
    campaignNameSearch: 'Survey name containing',
    customers: 'Customer',
    teams: 'Business challenge'
  };

  const isValue = value => (value || value === 0) && value !== '';

  const transformRecordsInFilter = f => {
    const newFilter = {
      ...(selectedCustomers && selectedCustomers.length
        ? { customers: selectedCustomers }
        : {}),
      ...(selectedTeams && selectedTeams.length
        ? { teams: selectedTeams }
        : {}),
      ...f
    };
    delete newFilter.records;
    return newFilter;
  };

  const transformedF =
    filter.records && filter.records.length
      ? transformRecordsInFilter(filter)
      : filter;

  useEffect(
    () => {
      let formattedFiltesList = [];
      Object.keys(transformedF).map(topFilter => {
        if (transformedF[topFilter].value && transformedF[topFilter].label) {
          if (isValue(transformedF[topFilter].value)) {
            formattedFiltesList.push({
              label: `${filterGroupLabel[topFilter]}: ${
                transformedF[topFilter].label
              }`,
              path: topFilter,
              value: transformedF[topFilter].value
            });
          }
        } else if (Array.isArray(transformedF[topFilter])) {
          const arrayFilter = transformedF[topFilter].map(
            (arrayFilterItem, index) => {
              if (isValue(arrayFilterItem.value)) {
                return {
                  label: `${filterGroupLabel[topFilter]}: ${
                    arrayFilterItem.label
                  }`,
                  path: topFilter,
                  index,
                  value: arrayFilterItem.value
                };
              }
              return null;
            }
          );
          formattedFiltesList = [...formattedFiltesList, ...arrayFilter];
        } else {
          const arrayFilter = [];
          Object.keys(transformedF[topFilter]).forEach(subFilter => {
            if (isValue(transformedF[topFilter][subFilter].value)) {
              arrayFilter.push({
                label: `${filterGroupLabel[subFilter]}: ${
                  transformedF[topFilter][subFilter].label
                }`,
                path: `${topFilter}.${subFilter}`,
                value: transformedF[topFilter][subFilter].value
              });
            } else {
              Object.keys(transformedF[topFilter][subFilter]).forEach(
                thirdFilter => {
                  if (
                    isValue(
                      transformedF[topFilter][subFilter][thirdFilter].value
                    )
                  ) {
                    const dateElements = transformedF[topFilter][subFilter][
                      thirdFilter
                    ].value.split('T');
                    arrayFilter.push({
                      label: `${filterGroupLabel[subFilter]} ${
                        filterGroupLabel[thirdFilter]
                      }: ${dateElements[0]}`,
                      path: `${topFilter}.${subFilter}.${thirdFilter}`,
                      value:
                        transformedF[topFilter][subFilter][thirdFilter].value
                    });
                  }
                }
              );
            }
          });
          formattedFiltesList = [...formattedFiltesList, ...arrayFilter];
        }
        return null;
      });

      setFiltersList(formattedFiltesList);
    },
    [filter, selectedTeams, selectedCustomers]
  );

  const deepObjectValue = (obj, fullPathString) => {
    let object = obj;

    for (
      let i = 0,
        pathString = fullPathString.split('.'),
        len = pathString.length;
      i < len;
      i += 1
    ) {
      object = obj[pathString[i]];
    }
    return object;
  };

  const setDeepObjectValue = (obj, fullPathString, value) => {
    let object = obj;
    let lastObjectPath = null;

    for (
      let i = 0,
        pathString = fullPathString.split('.'),
        len = pathString.length;
      i < len;
      i += 1
    ) {
      lastObjectPath = pathString[i];

      if (i < len - 1) {
        object = object[pathString[i]];
      }
    }

    object[lastObjectPath] = value;

    return obj;
  };

  const setNewCampaignFiltersFromRecordsFiltering = (
    value,
    selectedRecords,
    setSelectedRecordsFunction,
    accountType
  ) => {
    const toRemoveIndex = selectedRecords.findIndex(
      team => team.value === value
    );
    const newSelectedRecords = [...selectedRecords];
    newSelectedRecords.splice(toRemoveIndex, 1);
    setSelectedRecordsFunction(newSelectedRecords);

    onAccountsSelectCompleted(accountType, newSelectedRecords);
  };

  const removeFilter = (path, value) => {
    if (path === 'customers' || path === 'teams') {
      setNewCampaignFiltersFromRecordsFiltering(
        value,
        path === 'teams' ? selectedTeams : selectedCustomers,
        path === 'teams' ? setSelectedTeams : setSelectedCustomers,
        path === 'teams' ? accountTypes.teams : accountTypes.customers
      );
    } else {
      let modifiedFilter = filter;

      if (Array.isArray(deepObjectValue(modifiedFilter, path))) {
        const newArray = deepObjectValue(modifiedFilter, path).filter(
          deepValue => deepValue.value !== value
        );

        modifiedFilter = setDeepObjectValue(modifiedFilter, path, newArray);
      } else {
        modifiedFilter = setDeepObjectValue(modifiedFilter, path, {
          value: '',
          label: ''
        });
      }

      setCampaignFilters(modifiedFilter);
    }

    return null;
  };

  return (
    <div className={styles.activeFiltersContainer}>
      {filtersList.map((activeFilter, index) => (
        <div
          className={`${
            styles.activeFilterItem
          } campaign-filters-active-filter-item`}
          key={`active-filter-container-${index.toString()}`}
        >
          <span>{activeFilter.label}</span>
          <span
            className={styles.removeIcon}
            role="presentation"
            onClick={() => removeFilter(activeFilter.path, activeFilter.value)}
          >
            <Icon type="close" />
          </span>
        </div>
      ))}
      {filtersList && filtersList.length ? (
        <span
          className={`${
            styles.clearAllFilters
          } campaign-filters-clear-all-filters-button`}
          role="presentation"
          onClick={() => {
            setCampaignFilters({});
            setSelectedTeams([]);
            setSelectedCustomers([]);
          }}
        >
          Clear all filters
        </span>
      ) : null}
    </div>
  );
};

export default ActiveFilters;
